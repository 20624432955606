import { RouteRecordRaw } from "vue-router";
import { appointmentMustExist } from "./guards";

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/sc/appointments',
        name: 'appointments',
        component: () => import('../appointments-calendar-view.vue'),
        children: [
            {
                path: ':appointmentId/details',
                name: 'appointmentDetails',
                beforeEnter: [appointmentMustExist],
                component: () => import('../offcanvas-appointment-details.vue'),
                props: {
                    eager: true
                }
            },
            {
                path: ':appointmentId/edit',
                name: 'editAppointment',
                beforeEnter: [appointmentMustExist],
                component: () => import('../offcanvas-appointment-editor.vue'),
                props: {
                    eager: true,
                    mode: 'edit'
                }
            },
            {
                path: 'new',
                name: 'newAppointment',
                component: () => import('../offcanvas-appointment-editor.vue'),
                props: {
                    eager: true,
                    mode: 'create'
                }
            },
            
        ]
    },
]