import { useOrderStore } from "@/modules/proportal/store";
import { NavigationGuard } from "vue-router";

/**
 * This guard ensures to load the order based on the consultationId into the order store.
 * However, it will not fail even if the order does not exist.
 * 
 * @param to 
 * @param from 
 */
export const orderMaybeExists: NavigationGuard = (to, from) => {
    const orderStore = useOrderStore();

    
    const consultationId = to.params['consultationId'] as string;
    if (orderStore.orderRecord && orderStore.orderRecord.consultationRecord.consultation.id !== consultationId) {
        orderStore.$reset()
    }

    if (!consultationId) {
        return;
    };

    orderStore.loadByConsultationId(consultationId)
}