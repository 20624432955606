import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"
import { routes as consultationRoutes } from './components/consultation/router';
import { routes as settingsRoutes } from './components/settings/router';
import { routes as schedulingRoutes } from './components/scheduling/router';
import { routes as clientRoutes } from './components/clients/router';
import { routes as educationRoutes } from './components/education/router';
import { useNProgress } from "@vueuse/integrations/useNProgress";
import { scrollIntoView } from "seamless-scroll-polyfill";
import { helpSupportRoutes } from "./components/help-support/pages/routes";

const routes: Array<RouteRecordRaw> = [
    ...consultationRoutes,
    ...settingsRoutes,
    ...schedulingRoutes,
    ...clientRoutes,
    ...educationRoutes,
    ...helpSupportRoutes
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if(to.hash && document.querySelector(to.hash) !== null) {
            return scrollIntoView(document.querySelector(to.hash)!);
        }
        return { top: 0 }
    }
})

const NProgress = useNProgress();

router.beforeResolve(async(to, from, next) => {
    if (to.path) {
        NProgress.start();
    }
    next();
});

router.afterEach(() => {
    NProgress.done();
});