import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";
import { defineStore } from "pinia";
import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import { ConsultationLearningNotesCreateRequest, ProductCategory, StepRequestModel } from "@/modules/proportal/types";
import { Consultation } from "@/modules/proportal/types";
import { usePortalConsultationStore, usePortalAuthStore } from "./";
import { CurrentSkinCareRoutine } from "../types/consultation-current-routine";

export const usePortalConsultationCurrentSkincareRoutines = defineStore('portalConsultationCurrentSkincareRoutines', {
    state: () => ({
        isBusyUpdating: false,
        currentSkincareRoutines: [] as Array<CurrentSkinCareRoutine>,
        productCategories: [] as Array<ProductCategory>
    }),
    getters: {
        getRoutineCategory: (state) => {
            return (categoryId: string) => {
                return state.currentSkincareRoutines.filter(x => x.skincareRoutineCategoryRecord.id === categoryId)
            }
        },
        getCategorySteps: (state) => {
            return (categoryId: string) => {
                const selectedCategory = state.currentSkincareRoutines.filter(x => x.skincareRoutineCategoryRecord.id === categoryId)[0]

                return selectedCategory.consultationCurrentSkincareRoutineStepRecords
            }
        },
        getStepsRequestModel: (state) => {
            return state.currentSkincareRoutines.map((x) => {
                return {
                    skincareRoutineCategoryId: x.skincareRoutineCategoryRecord.id,
                    consultationCurrentSkincareRoutineStepRequestModels: x.consultationCurrentSkincareRoutineStepRecords.map(y => {
                        return {
                            notes: y.notes,
                            productCategoryId: y.productCategoryRecord.id,
                            productId: y.productRecord?.id ?? '',
                            productBrandName: y.productRecord?.productBrandName ?? '',
                            productName: y.productRecord?.productName ?? ''
                        }
                    })
                }
            })
        },
    },
    actions: {
        async getCurrentSkincareRoutines(consultationId: string) {
            const request = axios.get<APIResponse<Array<CurrentSkinCareRoutine>>>(`${endpoints.CONSULTATIONS}/${consultationId}/current-skincare-routine`);
            const response = await useErrorHandling(request)
            this.currentSkincareRoutines = response.data.data;
        },
        async getProductCategories() {
            const request = axios.get<APIResponse<Array<ProductCategory>>>(`${endpoints.PRODUCT_CATEGORIES}`)
            const response = await useErrorHandling(request)
            this.productCategories = response.data.data;
        },
        async editCurrentRoutineStep(categoryId: string, stepId: String, newStepObj: StepRequestModel){
            const stepsRequestModel = this.getStepsRequestModel
            const consultationId = usePortalConsultationStore().currentConsultationId

            // Find step to replace
            const routineCategory = this.getRoutineCategory(categoryId)[0]
            const indexToChange = routineCategory.consultationCurrentSkincareRoutineStepRecords.findIndex(y => y.id === stepId)
            const currentRoutineCategory = stepsRequestModel.filter(y => y.skincareRoutineCategoryId === categoryId)

            // Replace step object
            currentRoutineCategory[0].consultationCurrentSkincareRoutineStepRequestModels[indexToChange] = newStepObj

            this.isBusyUpdating = true;

            try {
                const request = axios.post(`${endpoints.CONSULTATIONS}/${consultationId}/current-skincare-routine`, stepsRequestModel)
                const response = await useErrorHandling(request)
                this.currentSkincareRoutines = response.data.data
            } finally {
                this.isBusyUpdating = false;
            }
        },
        async removeCurrentRoutineStep(stepId: string, categoryId: string) {
            const consultationStore = usePortalConsultationStore()
            const consultationId = consultationStore.currentConsultationId

            const routineCategory = this.getRoutineCategory(categoryId)[0]
            const stepsArr = routineCategory.consultationCurrentSkincareRoutineStepRecords
            const removeFromArr = routineCategory.consultationCurrentSkincareRoutineStepRecords.findIndex(y => y.id === stepId)

            if (removeFromArr > -1) {
                stepsArr.splice(removeFromArr, 1)
            }

            const stepsRequestModel = this.getStepsRequestModel
            const request = axios.post(`${endpoints.CONSULTATIONS}/${consultationId}/current-skincare-routine`, stepsRequestModel)
            const response = await useErrorHandling(request)

            this.currentSkincareRoutines = response.data.data
        },
        async createCurrentRoutineStep(categoryId: string, newStepObj: StepRequestModel){
            const stepsRequestModel = this.getStepsRequestModel
            const consultationId = usePortalConsultationStore().currentConsultationId

            const currentRoutineCategory = stepsRequestModel.filter(y => y.skincareRoutineCategoryId === categoryId)

            currentRoutineCategory[0].consultationCurrentSkincareRoutineStepRequestModels.push(newStepObj)

            const request = axios.post(`${endpoints.CONSULTATIONS}/${consultationId}/current-skincare-routine`, stepsRequestModel)
            const response = await useErrorHandling(request)

            this.currentSkincareRoutines = response.data.data
        },
        async sortCurrentRoutineStep(){
            const consultationId = usePortalConsultationStore().currentConsultationId
            const stepsRequestModel = this.getStepsRequestModel

            const request = axios.post<APIResponse<Array<CurrentSkinCareRoutine>>>(`${endpoints.CONSULTATIONS}/${consultationId}/current-skincare-routine`, stepsRequestModel)
            const response = await useErrorHandling(request)

            this.currentSkincareRoutines = response.data.data
        },
        async saveLearningNotes(data: ConsultationLearningNotesCreateRequest) {
            const consultationStore = usePortalConsultationStore()
            const consultationId = consultationStore.currentConsultationId
            const request = axios.post<APIResponse<Consultation>>(`${endpoints.CONSULTATIONS}/${consultationId}/learning-notes`, data)
            const response = await useErrorHandling(request);

            consultationStore.consultation.consultation = response.data.data;
        },
        async duplicateMorningRoutine(targetRoutineId: string) {
            const consultationStore = usePortalConsultationStore()
            const consultationId = consultationStore.currentConsultationId
            const morningRoutineId = this.currentSkincareRoutines[0].skincareRoutineCategoryRecord.id

            const request = axios.put<APIResponse<Array<CurrentSkinCareRoutine>>>(`${endpoints.CONSULTATIONS}/${consultationId}/current-skincare-routine/clone?sourceId=${morningRoutineId}&targetId=${targetRoutineId}`)
            const response = await useErrorHandling(request);

            this.currentSkincareRoutines = response.data.data;
            
        }
    }
})