import { defineStore } from 'pinia';
import axios from 'axios';
import endpoints from '@/modules/proportal/endpoints';
import { useErrorHandling } from '@/modules/core/composables';
import { APIResponse } from '@/modules/core/types';
import type { ProductCategory } from '../types';

export const useProductCategoriesStore = defineStore('product-categories', {
    state: () => ({
        productCategories: [] as ProductCategory[],
        filters: {
            searchTerm: ''
        },
        busyStates: {
            isLoading: false,
        },
    }),
    getters: {
        filteredProductCategories: (state) => state.productCategories.filter(x => x.displayName.toLowerCase().includes(state.filters.searchTerm.toLowerCase()))
    },
    actions: {
        async getProductCategories(searchTerm = '') {
            this.$patch({
                productCategories: [],
                busyStates: {
                    isLoading: true,
                },
            });
            try {
                const request = axios.get<APIResponse<ProductCategory[]>>(
                    `${endpoints.PRODUCT_CATEGORIES}`
                );
                const response = await useErrorHandling(request);
                this.productCategories = response.data.data;
            } finally {
                this.busyStates.isLoading = false;
            }
        },
        async searchProductCategories(searchTerm = '') {
            this.filters.searchTerm = searchTerm;
        }
    },
});
