import AppConstants from '@/modules/proportal/app.constants';
import { usePortalAuthStore } from '@/modules/proportal/store/auth';
import { createFetch } from '@vueuse/core';

export const useMyFetch = createFetch({
    options: {
        async beforeFetch({ options, cancel }) {
            const authStore = usePortalAuthStore();
            if(!authStore.auth.accessToken) {
                cancel();
            }
            if(authStore.auth.accessToken) {
                options.headers = {
                    ...options.headers,
                    [AppConstants.AUTH_TOKEN_HTTP_HEADER_NAME]: `Bearer ${authStore.auth.accessToken}`
                }
            }
        
            return { options };
        },
    }
})