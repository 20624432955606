import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "../endpoints";
import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse, PaginatedData } from "@/modules/core/types";
import { SkinTypeAndConditionAnalysisRecord, AnalyticSkinMeasurementValueRecord } from "../types";
import { useAnalyticsStore } from './'

export const useAnalyticsSkinTypeAndConditionStore = defineStore('portalAnalyticsSkinTypeAndCondition', {
    state: () => ({
        // filters: {
        //     fromDateTime: dayjs().format('YYYY-MM-DD'),
        //     toDateTime: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        //     timeInterval: 'Daily',
        //     pageNumber: 1,
        //     pageSize: 1
        // },
        isBusy: false,
        skinTypeAndConditionAnalysisRecords: null as PaginatedData<SkinTypeAndConditionAnalysisRecord>|null,
        skinMeasurementValueIndex: 0
    }),
    getters: {
        hasZeroSkinMeasurementValue: () => (records: Array<AnalyticSkinMeasurementValueRecord>) => {
            const recordsLength = records.length;
            let zeroesCount = 0;
            
            records.forEach(x => {
                if(x.count === 0) zeroesCount++;
            })

            return recordsLength === zeroesCount;
        },
        getPercentage: () => (records: Array<AnalyticSkinMeasurementValueRecord> = [], count: number, sum: number) => {
            // const sum = records.reduce((a, b) => a + b.count, 0);
            const percentage = (count / sum) * 100;
            if(!isNaN(percentage)) {
                if(percentage === 0) return '0%';
                if(percentage === 100) return '100%';
                return `${percentage.toFixed(1)}%`;
            }
            return '0%';
        },
        getNotApplicablePercentage: (state) => (notApplicableCount: number, sum: number) => {
            // const records = state.skinTypeAndConditionAnalysisRecords;

            // if(!records || (records && !records.items.length)) return;

            // const notApplicableCount = records.items[0].analyticSkinMeasurementSectionRecords[skinMeasurementSectionRecordIndex].notApplicableCount;

            const percentage = (notApplicableCount / sum) * 100;
            if(!isNaN(percentage)) {
                if(percentage === 0) return '0%';
                if(percentage === 100) return '100%';
                return `${percentage.toFixed(1)}%`;
            }
            return '0%';


            // const totalNotApplicable = records.items[0].analyticSkinMeasurementSectionRecords.reduce((a, b) => a + b.notApplicableCount, 0);

            // const percentage = 
        },
        filters: () => useAnalyticsStore().filters
    },
    actions: {
        async load() {
            this.skinMeasurementValueIndex = 0;
            this.isBusy = true;
            const request = axios.get<APIResponse<PaginatedData<SkinTypeAndConditionAnalysisRecord>>>(
                `${endpoints.ANALYTICS}/skin-type-and-condition`, { params: this.filters }
            );
            const response = await useErrorHandling(request);

            this.skinTypeAndConditionAnalysisRecords = response.data.data;
            this.isBusy = false;
        },
        async onNextPage() {
            if(!this.skinTypeAndConditionAnalysisRecords) return;
            if(this.filters.pageNumber >= this.skinTypeAndConditionAnalysisRecords.totalPages) return;
            this.filters.pageNumber++;
            await this.load();
        },
        async onPrevPage() {
            if(this.filters.pageNumber <= 1) return;
            this.filters.pageNumber--;
            await this.load();
        },
        async onPageChange(pageNumber: number) {
            this.filters.pageNumber = pageNumber;
            await this.load();
        },
    }
})