import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "../endpoints";
import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import { AnalyticsSummaryRecord, AnalyticSkinMeasurementValueRecord } from "../types";
import { usePortalAuthStore, useAnalyticsStore, useOrganisationStore } from "@/modules/proportal/store";

export const useAnalyticsSummaryStore = defineStore('portalAnalyticsSummary', {
    state: () => ({
        isBusy: false,
        analyticsSummary: null as AnalyticsSummaryRecord|null
    }),
    getters: {
        // Nested object getters
        skinTypeAndConditionAnalysisRecords: (state) => state.analyticsSummary?.skinTypeAndConditionAnalysisRecords,
        clientSummaryAnalysisRecord: (state) => state.analyticsSummary?.clientSummaryAnalysisRecord,
        inventorySummaryAnalysisRecord: (state) => state.analyticsSummary?.inventorySummaryAnalysisRecord,
        clientCountrySummaryAnalysisRecord: (state) => state.analyticsSummary?.clientCountrySummaryAnalysisRecord,
        currentRangeIngredientRecordFrequencyRecords: (state) => state.analyticsSummary?.inventorySummaryAnalysisRecord.currentRangeIngredientRecordFrequencyRecords,
        currentRangeProductRecordFrequencyRecords: (state) => state.analyticsSummary?.inventorySummaryAnalysisRecord.currentRangeProductRecordFrequencyRecords,
        clientCountryFrequencyRecords: (state) => state.analyticsSummary?.clientCountrySummaryAnalysisRecord.clientCountryFrequencyRecords,
        top5ClientCountryFrequencyRecords: state => {
            const clientCountryFrequencyRecordsCopy = state.analyticsSummary?.clientCountrySummaryAnalysisRecord.clientCountryFrequencyRecords;
            return clientCountryFrequencyRecordsCopy?.sort((a, b) => b.count - a.count).slice(0, 5);
        },
        customerTerminology: () => useOrganisationStore().customerTerminology,
        // Other getters
        getAllMostSelectedSkinMeasurementValues: (state) => (records: Array<AnalyticSkinMeasurementValueRecord>): Array<AnalyticSkinMeasurementValueRecord> => {
            const mostSelectedRecord = records.reduce((a, b) => a.count > b.count ? a : b);
            const recordsWithHighestCount: Array<AnalyticSkinMeasurementValueRecord> = [];
            
            if (mostSelectedRecord) {
                const highestCount = mostSelectedRecord.count;
                // if (highestCount === 0) return [];

                const allCountIsZero = records.every(record => record.count === 0);
                if (allCountIsZero) {
                    recordsWithHighestCount.push(records[0]) // any ASMValueRecord is fine since all have zero value, need at least one to render 'No Data Found'
                    return recordsWithHighestCount;
                }

                records.forEach(record => {
                    if (record.count === highestCount) {
                        recordsWithHighestCount.push(record);
                    }
                })

                return recordsWithHighestCount;
            }
            return [];
        },
        getMostSelectedSkinMeasurementValue: () => (records: Array<AnalyticSkinMeasurementValueRecord>) => {
            const mostSelectedRecord = records.reduce((a, b) => a.count > b.count ? a : b);
            if(mostSelectedRecord) {
                return mostSelectedRecord;
            }
            return null;
        },
        getMostSelectedSkinMeasurementValuePercentage: () => (recordsFromLoop: Array<AnalyticSkinMeasurementValueRecord>, sum: number) => {
            const mostSelectedRecord = recordsFromLoop.reduce((a, b) => a.count > b.count ? a : b);
            // const sum = recordsFromLoop.reduce((a, b) => a + b.count, 0);

            const percentage = (mostSelectedRecord.count / sum) * 100;

            return `${!isNaN(percentage) ? percentage.toFixed(1) : 0}%`;
        },
        // getIngredientsPercentage: () => (recordsFromLoop: Array<CurrentRangeIngredientRecordFrequencyRecord>, count: number) => {
        //     const sum =  recordsFromLoop.reduce((a, b) => a + b.count, 0);
        //     const percentage = (count / sum) * 100;
            
        //     if(!isNaN(percentage)) {
        //         if(percentage === 0) return '0%';
        //         if(percentage === 100) return '100%';
        //         return `${percentage.toFixed(1)}%`;
        //     }
        //     return '0%';
        // },
        filters: () => useAnalyticsStore().filters
    },
    actions: {
        async load() {
            this.isBusy = true;
            const request = axios.get<APIResponse<AnalyticsSummaryRecord>>(
                `${endpoints.ANALYTICS}/summary`,
                {
                   params: this.filters
                }
            );
            const response = await useErrorHandling(request);

            this.analyticsSummary = response.data.data;
            this.isBusy = false;
        }
    }
})