import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { IngredientRecord } from "../types";

export const useIngredientStore = defineStore('ingredient', {
    state: () => ({
        ingredientId: '',
        ingredientRecord: null as IngredientRecord|null
    }),
    actions: {
        /**
         * Loads an `IngredientRecord` by id into the store. By default, it will bail
         * early if the ingredientId matches the existing record in the store.
         * @param ingredientId 
         * @param force pass true to force reload the ingredientId record.
         */
        async loadById (ingredientId: string, force = false) {
            if (!force && (ingredientId === this.ingredientId) && this.ingredientRecord) return;
            this.ingredientRecord = null;
            this.ingredientId = '';

            if (!ingredientId) return;

            const request = axios.get<APIResponse<IngredientRecord>>(
                `${endpoints.INGREDIENTS}/${ingredientId}`
            );
            const response = await useErrorHandling(request);

            this.ingredientId = ingredientId;
            this.ingredientRecord = response.data.data;
        }
    }
})