import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";
import { useErrorHandling } from "@/modules/core/composables";
import { ClientRecord, CreateClientRequest } from "../types";
import { APIResponse, PaginatedData } from "@/modules/core/types";
import { useOrganisationStore } from "@/modules/proportal/store";
import { useCommonDataStore } from "@/modules/proportal/store";

export const useClientsStore = defineStore('clients', {
    state: () => ({
        filters: {
            searchParam: '',
            countryId: '',
            pageNumber: 1
        },
        isBusy: false,
        clientsPaginated: null as PaginatedData<ClientRecord>|null,
    }),
    getters: {
        hasFilters: state => !!state.filters.searchParam || !!state.filters.countryId,
        customerTerminologyName: () => useOrganisationStore().customerTerminology?.name,
        totalClients: (state) => state.clientsPaginated?.count ?? 0,
    },
    actions:{
        async getClients() {
            this.isBusy = true;
            const request = axios.get<APIResponse<PaginatedData<ClientRecord>>>(endpoints.CLIENTS, {
                params: {
                    ...this.filters,
                    pageSize: 9
                }
            });
            const response = await useErrorHandling(request);
            this.isBusy = false;
            this.clientsPaginated = response.data.data;
        },
        async createClient(data: CreateClientRequest) {
            const request = axios.post<APIResponse<ClientRecord>>(
                endpoints.CLIENTS,
                data
            );
            const response = await useErrorHandling(request);
            this.$patch({
                filters: {
                    searchParam: '',
                    countryId: '',
                    pageNumber: 1
                }
            })
            this.getClients();
            // this.clientsPaginated?.items.push(response.data.data)
            return response.data.data
        },
        async onNextPage() {
            if(!this.clientsPaginated) return;
            if(this.filters.pageNumber >= this.clientsPaginated.totalPages) return;
            this.filters.pageNumber++;
            await this.getClients();
        },
        async onPrevPage() {
            if(this.filters.pageNumber <= 1) return;
            this.filters.pageNumber--;
            await this.getClients();
        },
        async onPageChange(pageNumber: number) {
            this.filters.pageNumber = pageNumber;
            await this.getClients();
        },
        async deleteById (clientId: string) {
            const request = axios.delete<APIResponse<ClientRecord>>(
                `${endpoints.CLIENTS}/${clientId}`
            );
            await useErrorHandling(request);
            await this.getClients();
        }
    }
})