import { RouteRecordRaw } from "vue-router";
import { orderMaybeExists } from "../../consultation/order/guards";
import { organisationMustActive, organisationMustHaveSubscribedBefore, memberMustBeAdminOrOwner } from './guards';

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/s',
        component: () => import('../portal-settings-page-layout.vue'),
        redirect: { name: 'accountDetails' },
        children: [
            {
                path: 'account-details',
                name: 'accountDetails',
                component: () => import('../account-details/portal-settings-account-details-page.vue'),
                beforeEnter: [organisationMustActive],  
            },
            {
                path: 'consultation-settings',
                name: 'consultationSettings',
                component: () => import('../consultation-settings/portal-settings-consultation-settings-page.vue'),
                beforeEnter: [organisationMustActive]
            },
            {
                path: 'inventory',
                name: 'inventory',
                component: () => import('../inventory/portal-settings-inventory-page.vue'),
                beforeEnter: [organisationMustActive],
                redirect: 'inventory/products',
                children: [
                    {
                        path: 'products',
                        name: 'inventoryProducts',
                        component: () => import('../inventory/inventory-products-page.vue'),
                        beforeEnter: [organisationMustActive]
                    },
                    {
                        path: 'treatments',
                        name: 'inventoryTreatments',
                        component: () => import('../inventory/inventory-treatments-page.vue'),
                        beforeEnter: [organisationMustActive]
                    }
                ]
            },
            {
                path: 'portal-settings',
                name: 'portalSettings',
                component: () => import('../portal-settings/portal-settings-portal-settings-page.vue'),
                beforeEnter: [organisationMustActive]
            },
            {
                path: 'organisation-settings',
                name: 'organisationSettings',
                component: () => import('../organisation/portal-settings-organisation-page.vue'),
                beforeEnter: [organisationMustActive]
            },
            {
                path: 'ingredients',
                name: 'portalSettingsIngredients',
                component: () => import('../ingredients/portal-settings-ingredients-page.vue'),
                beforeEnter: [organisationMustActive]
            },
            {
                path: 'billing',
                name: 'portalSettingsBilling',
                component: () => import('../billing/portal-billing-page.vue'),
                beforeEnter: [
                    organisationMustHaveSubscribedBefore,
                    memberMustBeAdminOrOwner,
                ],
            },
            {
                path: 'members',
                name: 'portalSettingsMembers',
                component: () => import('../members/portal-members-page.vue'),
                beforeEnter: [organisationMustHaveSubscribedBefore],
            },
            {
                path: 'billing/plans',
                name: 'portalSettingsBillingPlans',
                component: () => import('../billing/portal-billing-plans-page.vue'),
                beforeEnter: [memberMustBeAdminOrOwner]
            },
            {
                path: 'billing/payment-status',
                name: 'portalSettingsBillingPaymentStatus',
                component: () => import('../billing/portal-billing-payment-status-page.vue'),
            },
            {
                path: 'order-history',
                name: 'portalSettingsOrderHistory',
                component: () => import('../order-history/portal-settings-order-history-page.vue'),
                beforeEnter: [organisationMustActive],
                children: [
                    {
                        path: ':consultationId',
                        name: 'portalSettingsOrderHistoryDetails',
                        component: () => import('../../consultation/order/offcanvas-order-history-details.vue'),
                        props: {
                            eager: true,
                        },
                        meta: { previousRoute: 'portalSettingsOrderHistory' },
                        beforeEnter: [organisationMustActive, orderMaybeExists]
                    }
                ]
            }
        ]
    },
]