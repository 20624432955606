import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";
import { defineStore } from "pinia";
import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import { ConsultationFinalNoteRecord, ConsultationFinalNoteRecordUpdateRequest, FinalNote } from "../types";

export const usePortalConsultationFinalNotesStore = defineStore('portalConsultationFinalNotes', {
    state: () => ({
        finalNotes: [] as Array<FinalNote>,
        consultationFinalNoteRecords: [] as Array<ConsultationFinalNoteRecord>,
        isBusy: false
    }),
    actions: {
        async getFinalNotes() {
            const request = axios.get<APIResponse<Array<FinalNote>>>(`${endpoints.FINAL_NOTES}`);
            const response = await useErrorHandling(request);
            this.finalNotes = response.data.data;
        },
        async getFinalNotesByConsultantId(consultantId: string, map = true) {
            if (!consultantId) return;

            this.isBusy = true;
            const request = axios.get<APIResponse<Array<ConsultationFinalNoteRecord>>>
                (`${endpoints.CONSULTATIONS}/${consultantId}/final-note-selections`);
            const response = await useErrorHandling(request);
            this.consultationFinalNoteRecords = response.data.data ?? this.finalNotes;

            if (map) this.mapNoteRecordsToNotes();
            this.isBusy = false;
        },
        mapNoteRecordsToNotes () {
            this.finalNotes = this.finalNotes.map(x => {
                const record = this.consultationFinalNoteRecords.find(record => record.id === x.key);
    
                if (record) {
                    return {
                        ...x,
                        active: record.isSelected
                    }
                } 
                
                return {
                    ...x,
                    active: false
                };
            });
        },
        getNoteRecordsUpdateRequest () { 
            return this.finalNotes.map(note => {
                return {
                    finalNoteId: note.key,
                    isSelected: note.active
                }
            })
        },
        async updateFinalNotesByConsultantId(consultantId: string) {
            if (!consultantId) return;

            const payload = this.getNoteRecordsUpdateRequest();
            if (!payload) return;

            this.isBusy = true;
            const request = axios.post<APIResponse<Array<ConsultationFinalNoteRecord>>>
                (`${endpoints.CONSULTATIONS}/${consultantId}/final-note-selections`, payload);
            const response = await useErrorHandling(request);

            this.consultationFinalNoteRecords = response.data.data;
            this.isBusy = false;
        },
        
    }
})