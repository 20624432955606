import { date, number, object, mixed, SchemaOf, string } from "yup";
import { ClientFormFields, NewClientAppointmentFormFields, NewConsultationFromClientFormFields } from "./types";
import { MaybeRef, computed, unref } from "vue";

export const clientDetailsSchema: SchemaOf<ClientFormFields> = object({
    name: string().trim().required().default('').label('Name'),
    email: string().trim().email().required().default('').label('Email'),
    phoneNumber: string().default('').required().label('Phone Number'),
    genderId: string().trim().required().default('').label('Gender'),
    dateOfBirth: string().default('').required().label('Date of birth'),
    countryId: string().trim().required().nullable().default('').label('Country/Region'),
    timezoneId: string().required().nullable().default('').label('Country'),
})

export const newClientAppointmentFormFieldsSchema: SchemaOf<NewClientAppointmentFormFields> = object({
    appointmentTypeId: string().required(),
    appointmentDate: date()
        .nullable()
        .required()
        .label('Appointment Date'),
    startTime: mixed()
        .required()
        .label('Start Time'),
    endTime: mixed()
        .required()
        .label('End Time'),
    duration: number().min(1, 'Duration is a required field').required().label('Duration'),
    notes: string().default('').nullable(),
    clientId: string().default('').label('Client')
});

export const useNewClientAppointmentFormFieldsSchema = (isClashing: MaybeRef<boolean>) => {
    return computed(() => {
        return object({
            ...newClientAppointmentFormFieldsSchema.fields,
            startTime: mixed()
                .required()
                .test('Start time should not clash', 'Your appointment time is clashing', () => {
                    if (unref(isClashing)) return false;
                    return true;
                })
                .label('Start Time'),
        });
    })
};

export const newConsultationFromClientFormFieldsSchema: SchemaOf<NewConsultationFromClientFormFields> = object({
    clientId: string().default('').required(),
    consultationMethodId: string().default('').required('Consultation method is a required field')
})