import { defineStore } from "pinia";
import { usePortalConsultationStore } from "./consultation";
import axios from "axios";
import endpoints from "../endpoints";
import { APIResponse } from "@/modules/core/types";
import { Consultation } from "../types";
import { useErrorHandling } from "@/modules/core/composables";

export const useConsultationClinicalTreatmentNotesStore = defineStore('consultation-clinicalTreatment-notes', {
    state: () => ({
        consultationId: '',
        treatmentNotes: ''
    }),
    actions: {
        load() {
            const store = usePortalConsultationStore();
            const consultationId = store.consultation.consultation.id;
            if (!consultationId) return;
            
            this.consultationId = store.consultation.consultation.id;
            this.$patch({
                consultationId,
                treatmentNotes: store.consultation.consultation.treatmentNotes ?? ''
            })
        },
        async save (treatmentNotes: string) {
            const consultationId = this.consultationId;
            if (!consultationId) return;
            
            this.treatmentNotes = treatmentNotes;

            const request = axios.post<APIResponse<Consultation>>(
                `${endpoints.CONSULTATIONS}/${consultationId}/treatment-notes`,
                { treatmentNotes }
            );

            const response = await useErrorHandling(request);

            usePortalConsultationStore().$patch({
                consultation: {
                    consultation: response.data.data
                }
            })
        }
    }
})