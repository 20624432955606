import { getMetaTagValue } from "@/modules/core/utilities";
import endpoints from "./endpoints";

export default {
    AUTH_STORAGE_KEY_NAME: 'findTalents.auth',
    AUTH_TOKEN_HTTP_HEADER_NAME: 'x-member-authorization',
    AUTH_TOKEN_COOKIE_NAME: 'SkinMaster.Member.AccessToken',
    AUTH_INTERCEPTOR_WHITELIST: [
        endpoints.CONSULTANTS
    ],
    SIGNIN_PAGE_URL: getMetaTagValue('signInPageUrl'),
    SIGNUP_PAGE_URL: getMetaTagValue('signUpPageUrl'),
    CURRENCY_CODE_DEFAULT: 'USD',
    CONSULTATION_STATUSES: {
        COMPLETED: '3c09e65b-f848-4b33-8aa4-e4a448e6b353'
    },
    CHART_COLOURS: [
        // '#fbf7f5',
        // '#f7ede9',
        // '#f1dfd7',
        // '#e3c3b4',
        // '#d5a994',
        // '#c38a70',
        // '#ad7155',
        // '#915d44',
        // '#794f3b',
        // '#664536',

        // '#EDEDE9',
        // '#D6CCC2',
        // '#F5EBE0',
        // '#E3D5CA',
        // '#D5BDAF',
        // '#F5E8C7',
        // '#DEBA9D',
        // '#9E7777',
        // '#6F4C5B',
        // '#cb997e',
        // '#ddbea9',
        // '#ffe8d6',
        // '#b7b7a4',
        // '#a5a58d',
        // '#6b705c'

        '#540d6e',
        '#ee4266',
        '#ffd23f',
        '#3bceac',
        '#0ead69',
        '#55dde0',
        '#33658a',
        '#2f4858',
        '#f6ae2d',
        '#f26419',
        // '#',
        // '#',
        // '#',
        // '#',
        // '#',
    ],
    ANALYTICS_TIME_INTERVALS: ['Daily', 'Weekly', 'Monthly', 'Annually', 'Lifetime']
}