import { defineStore } from "pinia";
import { usePortalAuthStore, useCommonDataStore, useClientsStore } from "@/modules/proportal/store";
import type { SubscriptionPlan, CustomerTerminology, Currency } from "@/modules/proportal/types";

export const useOrganisationStore = defineStore('portalOrganisation', {
    getters: {
        selectedOrganisation: () => usePortalAuthStore().selectedOrganisation,
        selectedOrganisationId (): string|undefined {
            return this.selectedOrganisation?.key;
        },
        hasActiveSubscription(): boolean {
            return this.selectedOrganisation?.hasActiveSubscription ?? false;
        },
        hasAnySubscription(): boolean {
            return this.selectedOrganisation?.hasAnySubscription ?? false;
        },
        isOrganisationOnFreeTrial(): boolean {
            return this.selectedOrganisation?.isOnFreeTrial ?? false;
        },
        isManualSubscription(): boolean {
            return this.selectedOrganisation?.isManualSubscription ?? false;
        },
        isAllowedToViewRestrictedContent(): boolean {
            return this.hasActiveSubscription && !this.isOrganisationOnFreeTrial;
        },
        activeSubscription(): SubscriptionPlan|null {
            if(!this.selectedOrganisation) return null;
            return this.selectedOrganisation.subscriptionPlan;
        },
        customerTerminology(): CustomerTerminology|undefined {
            return this.selectedOrganisation?.customerTerminology;
        },
        portalCurrency(): Currency|undefined {
            const currenciesListing = useCommonDataStore().currenciesListing;
            const userCurrency = this.selectedOrganisation?.portalCurrency;
            if (userCurrency) return userCurrency;

            if(currenciesListing && currenciesListing.default) {
                return currenciesListing.default;
            }
        },
        maximumClients(): number {
            return this.selectedOrganisation?.maximumClient ?? 0
        },
        maximumConsultants(): number {
            return this.selectedOrganisation?.maximumConsultant ?? 0
        },
        isClientsQuotaExceeded(): boolean {
            return useClientsStore().totalClients > this.maximumClients ?? false;
        },
        isClientsQuotaReached(): boolean {
            return useClientsStore().totalClients === this.maximumClients ?? false;
        }
    }
})