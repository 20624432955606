import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { ProductRecord } from "../types";

export const useProductStore = defineStore('product', {
    state: () => ({
        productId: '',
        productRecord: null as ProductRecord|null
    }),
    actions: {
        /**
         * Loads a `ProductRecord` by id into the store. By default, it will bail
         * early if the productId matches the existing record in the store.
         * @param productId 
         * @param force pass true to force reload the product record.
         */
        async loadById (productId: string, force = false, store = true) {
            if (!force && (productId === this.productId) && this.productRecord) return;
            this.productRecord = null;
            this.productId = '';

            if (!productId) return;

            const request = axios.get<APIResponse<ProductRecord>>(
                `${endpoints.PRODUCTS}/${productId}`
            );
            const response = await useErrorHandling(request);

            if(store) {
                this.productId = productId;
                this.productRecord = response.data.data;
            } else {
                return response.data.data;
            }
        },
    }
})