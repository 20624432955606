import { createPinia } from 'pinia';
// import { portalAuthPlugin } from './auth';

const pinia = createPinia();

// pinia.use(portalAuthPlugin)

export default pinia;

export * from './analytics';
export * from './analytics-summary';
export * from './analytics-skin-type-and-condition';
export * from './analytics-clients';
export * from './analytics-consultations';
export * from './analytics-appointments';
export * from './appointment';
export * from './appointments';
export * from './article-topics';
export * from './article-topics-detail';
export * from './article-topics-article-detail';
export * from './articles';
export * from './auth';
export * from './brands';
export * from './client';
export * from './client-consultations';
export * from './client-orders';
export * from './clients';
export * from './consultation-current-skincare-routine';
export * from './consultation-custom-ingredients';
export * from './consultation-final-notes';
export * from './consultation-instructions';
export * from './consultation-new-skincare-routines';
export * from './consultation-clinical-treatment-notes';
export * from './consultation-clinicial-treatments';
export * from './consultation-personal-health';
export * from './consultation-photos';
export * from './consultation-skin-mapping';
export * from './consultation-skin-needs';
export * from './consultation-suitable-ingredients';
export * from './consultation-process';
export * from './consultation';
export * from './consultations';
export * from './cookies';
export * from './ingredient';
export * from './ingredient-benefits';
export * from './ingredient-functions';
export * from './ingredients';
export * from './proportal';
export * from './order';
export * from './orders';
export * from './product';
export * from './products';
export * from './product-categories';
export * from './settings';
export * from './settings-billing';
export * from './settings-members';
export * from './settings-inventory';
export * from './settings-clinical-treatments';
export * from './settings-ingredients';
export * from './settings-consultation-settings';
export * from './skin-measurements-sections';
export * from './organisation';
