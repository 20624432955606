import { defineStore } from "pinia";
import { APIResponse } from "@/modules/core/types";
import { useErrorHandling } from "@/modules/core/composables";
import type { 
    PortalConsultantMember, 
    Organisation,
    UpdateConsultantAccountDetailsRequest, 
    UpdateOrganisationBasicInfoRequest, 
    UpdatePortalSettingsRequest
} from "@/modules/proportal/types";
import { usePortalAuthStore, useOrganisationStore } from '@/modules/proportal/store';
import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";
import { serialize } from 'object-to-formdata';
import { useNotifications } from '@/modules/core/composables';

const notifications = useNotifications();

export const usePortalSettingsStore = defineStore('portalSettings', {
    state: () => ({
        isBusy: false
    }),
    actions: {
        async updateAccountDetails(data: UpdateConsultantAccountDetailsRequest) {
            this.isBusy = true;
            const request = axios.put<APIResponse<PortalConsultantMember>>(
                `${endpoints.CONSULTANTS}/basic-infos`,
                data
            );
            const response = await useErrorHandling(request);

            const portalAuthStore = usePortalAuthStore();
            portalAuthStore.auth.consultant = response.data.data;

            this.isBusy = false;

            notifications.showToast({
                message: 'Basic info saved successfully',
                type: 'success'
            }); 
            
        },
        async changeProfilePicture (File: File) {
            const formData = new FormData();
            formData.append('File', File);

            const request = axios.put<APIResponse<PortalConsultantMember>>(
                `${endpoints.CONSULTANTS}/profile-picture`,
                formData
            );
            const response = await useErrorHandling(request);

            usePortalAuthStore().$patch({
                auth: {
                    consultant: response.data.data
                }
            })

            notifications.showToast({
                message: 'Profile picture saved successfully',
                type: 'success'
            }); 
        },
        async updatePortalSettings (data: UpdatePortalSettingsRequest) {
            const authStore = usePortalAuthStore();
            const request = axios.put<APIResponse<Organisation>>(
                `${endpoints.ORGANISATIONS}/${useOrganisationStore().selectedOrganisationId}/portal-settings`,
                data
            );

            const response = await useErrorHandling(request);

            if(authStore.auth.consultant) {
                authStore.auth.consultant.selectedOrganisation = response.data.data;
            }

            notifications.showToast({
                message: 'Portal settings saved successfully',
                type: 'success'
            });
        },
        async updateOrganisationBasicInfo (data: UpdateOrganisationBasicInfoRequest) {
            const authStore = usePortalAuthStore();
            const formData = serialize(data);

            const request = axios.put<APIResponse<Organisation>>(
                `${endpoints.ORGANISATIONS}/${useOrganisationStore().selectedOrganisationId}/basic-infos`,
                formData
            );

            const response = await useErrorHandling(request);

            if(authStore.auth.consultant) {
                authStore.auth.consultant.selectedOrganisation = response.data.data;
            }

            notifications.showToast({
                message: 'Organisation basic info saved successfully',
                type: 'success'
            }); 
        }
    }
})