import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia"
import endpoints from "../endpoints";
import { OrderRecord } from "../types";
import { useClientStore } from "./client"

export const useClientOrdersStore = defineStore('client-orders', {
    state: () => ({
        orderRecords: [] as Array<OrderRecord>
    }),
    getters: {
        clientRecord: () => useClientStore().clientRecord
    },
    actions: {
        async load () {
            if (!this.clientRecord) return;
            const clientId = this.clientRecord.client.id;

            const request = axios.get<APIResponse<Array<OrderRecord>>>(
                `${endpoints.ORDER_HISTORY}/clients/${clientId}`
            );
            const response = await useErrorHandling(request);
            this.orderRecords = response.data.data;
        }
    }
})