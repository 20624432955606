<template>
    <div class="toast-container position-fixed p-3 end-0" style="z-index: 1200">
        <div class="container vstack gap-3 align-items-center">
            <Toast
                v-for="(toast, index) in toasts"
                :key="index"
                :toast="toast"
                @hidden="removeToast(toast)"
            ></Toast>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Toast as ToastType } from "@/modules/core/types";
import Toast from './toast.vue';
import { useNotifications } from "@/modules/core/composables";

const { toasts, handleToastHidden } = useNotifications();

const removeToast = (toast: ToastType) => {
    handleToastHidden(toast)
}
</script>

<style lang="scss">

.toast-container {
    bottom: 0;
    width: 100%;
}
</style>