<template></template>

<script lang="ts" setup>
import { useCommonDataStore } from '@/modules/proportal/store/proportal'
import {
    Gender,
    Country,
    Currency,
    CurrenciesListing,
    FaceZone,
    SkinMeasurementSection,
    SkinCondition,
    ConsultationMethod,
    ConsultationStatus,
    SkincareRoutineCategory,
    PortalColorTheme,
    CustomerTerminology,
    AppointmentType,
    AppointmentDuration,
    ReportTextColour,
    ReportColourTheme,
    Instruction,
    PortalSettings
} from '@/modules/proportal/types'

interface Props {
    genders?: Array<Gender>;
    countries?: Array<Country>;
    currencies?: Array<Currency>;
    currenciesListing?: CurrenciesListing;
    faceZones?: Array<FaceZone>;
    skinConditions?: Array<SkinCondition>;
    skinMeasurementSections?: Array<SkinMeasurementSection>;
    consultationMethods?: Array<ConsultationMethod>;
    consultationStatuses?: Array<ConsultationStatus>;
    skincareRoutineCategories?: Array<SkincareRoutineCategory>;
    portalColorThemes?: Array<PortalColorTheme>;
    customerTerminologies?: Array<CustomerTerminology>;
    appointmentTypes?: Array<AppointmentType>;
    appointmentDurations?: Array<AppointmentDuration>;
    reportTextColours?: Array<ReportTextColour>;
    reportColourThemes?: Array<ReportColourTheme>;
    clientInstructions?: Array<Instruction>;
    accuracyInstructions?: Array<Instruction>;
    customerPortalUrl?: string; 
    pricingTableIdWithTrial?: string; 
    publishableKeyWithTrial?: string; 
    pricingTableIdWithoutTrial?: string; 
    publishableKeyWithoutTrial?: string;
    portalSettings?: PortalSettings;
}

const props = withDefaults(defineProps<Props>(), {
    genders: () => [],
    countries: () => [],
    currencies: () => [],
    faceZones: () => [],
    skinConditions: () => [],
    skinMeasurementSections: () => [],
    consultationMethods: () => [],
    consultationStatuses: () => [],
    skincareRoutineCategories: () => [],
    portalColorThemes: () => [],
    customerTerminologies: () => [],
    appointmentTypes: () => [],
    appointmentDurations: () => [],
    reportTextColours: () => [],
    reportColourThemes: () => [],
    clientInstructions: () => [],
    accuracyInstructions: () => [],
    customerPortalUrl: '',
    pricingTableIdWithTrial: '',
    publishableKeyWithTrial: '',
    pricingTableIdWithoutTrial: '',
    publishableKeyWithoutTrial: '',
})

useCommonDataStore().$patch({
    ...props,
    ...(import.meta.env.MODE === 'development' && {
        pricingTableIdWithTrial: 'prctbl_1P1OH3DkGXH6UcOZbN8R55IY'
    })
});
</script>