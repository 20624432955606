import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";
import { useErrorHandling } from "@/modules/core/composables";
import { 
    ClientRecord,
    UpdateClientRequest,
    CreateClientAppointmentRequest,
    AppointmentRecord
} from "../types";
import { APIResponse } from "@/modules/core/types";
import { useClientsStore } from "./clients";

export const useClientStore = defineStore('client', {
    state: () => ({
        isSavingClientBusy: false,
        clientRecord: null as ClientRecord|null,
    }),
    actions: {
        async loadById (clientId: string) {
            const request = axios.get<APIResponse<ClientRecord>>(
                `${endpoints.CLIENTS}/${clientId}`
            );
            const response = await useErrorHandling(request);

            this.$patch({
                clientRecord: response.data.data
            })
        },
        async updateClient(clientId: string, data: UpdateClientRequest) {
            if(!clientId) return;

            this.isSavingClientBusy = true;

            const request = axios.put<APIResponse<ClientRecord>>(
                `${endpoints.CLIENTS}/${clientId}`, data
            );
            const response = await useErrorHandling(request);

            this.isSavingClientBusy = false;
            this.clientRecord = response.data.data;
            useClientsStore().getClients();
        },
        async createAppointment(data: CreateClientAppointmentRequest) {
            const request = axios.post<APIResponse<AppointmentRecord>>(
                `${endpoints.APPOINTMENTS}`,
                data
            );
            await useErrorHandling(request);
        }
    }
})