import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { ClientConsultationHistory } from "../types";
import { useClientStore } from "./client";

export const useClientConsultationsStore = defineStore('client-consultations', {
    state: () => ({
        consultations: [] as Array<ClientConsultationHistory>,
    }),
    getters: {
        clientRecord: () => useClientStore().clientRecord,
        lastConsultationDate: state => state.consultations[0]?.date ?? ''
    },
    actions: {
        async load() {
            if (!this.clientRecord) return;

            const clientId = this.clientRecord.client.id;

            const request = axios.get<APIResponse<Array<ClientConsultationHistory>>>(
                `${endpoints.CLIENTS}/${clientId}/consultations`
            );

            const response = await useErrorHandling(request);

            this.consultations = response.data.data;
        }
    }
})