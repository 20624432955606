import { RouteRecordRaw } from "vue-router";

export const helpSupportRoutes: RouteRecordRaw[] = [
    {
        name: 'helpSupport-home',
        path: '/hs',
        component: () => import('./home.vue')
    },
    {
        name: 'helpSupport-categoryDetails',
        path: '/hs/categories/:categoryId',
        component: () => import('./category-details.vue'),
        props: true
    },
    {
        name: 'helpSupport-topicDetails',
        path: '/hs/categories/:categoryId/topics/:topicId',
        component: () => import('./topic-details.vue'),
        props: true
    }
]