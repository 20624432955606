import axios from "axios";
import { MaybeRef, unref } from "vue";
import endpoints from "../endpoints";
import { APIResponse } from "@/modules/core/types";
import { AppointmentDateGroupRecord } from "../types";
import { useErrorHandling } from "@/modules/core/composables";



export const getAppointments = async (fromDateTime: MaybeRef<Date>, toDateTime: MaybeRef<Date>, signal?: AbortSignal) => {

    const request = axios.get<APIResponse<Array<AppointmentDateGroupRecord>>>(
        `${endpoints.APPOINTMENTS}`,
        { 
            params: {
                fromDateTime: unref(fromDateTime),
                toDateTime: unref(toDateTime),
                // timezoneOffset: !timezoneOffset ? undefined : new Date().getTimezoneOffset()
            },
            signal
        }
    );
    const response = await useErrorHandling(request);

    return response.data
}