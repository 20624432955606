import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "../endpoints";
import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import { AnalyticsConsultationsRecord } from "../types";
import { useAnalyticsStore } from './'

export const useAnalyticsConsultationsStore = defineStore('portalAnalyticsConsultations', {
    state: () => ({
        isBusy: false,
        analyticsConsultationsRecords: null as AnalyticsConsultationsRecord|null
    }),
    getters: {
        consultationStatusAnalysisRecords: (state) => state.analyticsConsultationsRecords?.consultationStatusAnalysisRecords,
        consultationOrderHistoryPaymentStatusRecords: (state) => state.analyticsConsultationsRecords?.consultationOrderHistoryPaymentStatusRecords,
        consultationMethodAnalysisRecords: (state) => state.analyticsConsultationsRecords?.consultationMethodAnalysisRecords,
        // use this to document the getter
        /**
         * Returns the count value times two plus one.
         *
         * @returns {boolean}
         */
        hasZeroPaymentStatuses: (state) => {
            const records = state.analyticsConsultationsRecords?.consultationOrderHistoryPaymentStatusRecords;
            const frequencyRecords = records?.items[0].consultationOrderHistoryPaymentStatusFrequencyRecords;

            if(!frequencyRecords) return false;
 
            let zeroesCount = 0;
            frequencyRecords.forEach(x => {
                if(x.count === 0) zeroesCount++;
            })

            return frequencyRecords.length === zeroesCount;
        },
        filters: () => useAnalyticsStore().filters
    },
    actions: {
        async load() {
            this.isBusy = true;
            const request = axios.get<APIResponse<AnalyticsConsultationsRecord>>(
                `${endpoints.ANALYTICS}/consultations`, { params: this.filters }
            );
            const response = await useErrorHandling(request);

            this.analyticsConsultationsRecords = response.data.data;
            this.isBusy = false;
        },
    }
})