import { boolean, object, SchemaOf, string, number, ref } from "yup";
import type { 
    LoginFormFields, 
    EnterpriseConsultantRegistrationFormFields,
    SubscriberConsultantRegistrationFormFields, 
    ConsultantRegistrationFormFields,
    ConsultantRegistrationAuthFormFields, 
    ResetPasswordRequestFormFields,
    CompleteResetPasswordRequestFormFields
} from "./types";

export const loginFormFieldsSchema: SchemaOf<LoginFormFields> = object({
    email: string().email().default('').required().label('Email'),
    password: string().min(8).default('').required().label('Password'),
    rememberMe: boolean().default(false),
    otp: string().default('').label('OTP')
})

export const enterpriseConsultantRegistrationFormFieldsSchema: SchemaOf<EnterpriseConsultantRegistrationFormFields> = object({
    Name: string().default('').required().label('Name'),
    PhoneNumber: string().default('').required().label('Phone Number'),
    CountryId: string().default('').required().label('Country'),
    Location: string().default('').required().label('Location'),
    UserHasSkinMasterclassCertificationNumber: boolean().label('User Has Skin Masterclass Certification Number'),
    SkinMasterclassCertificationNumber: string().default('').label('Skin Masterclass Certification Number')
        .when('UserHasSkinMasterclassCertificationNumber', {
            is: true,
            then: schema => schema.required()
        }),
    BusinessName: string().default('').required().label('Business Name'),
    BusinessWebsiteUrl: string().url("Please ensure the url contains 'http://' or 'https://").default('').label('Business Website URL'),
})

export const subscriberConsultantRegistrationFormFieldsSchema: SchemaOf<SubscriberConsultantRegistrationFormFields> = object({
    Name: string().default('').required().label('Name'),
    // PhoneNumber: string().default('').required().label('Phone Number'),
    CountryId: string().default('').required().label('Country'),
    TimezoneId: string().default('').required().label('Timezone'),
    // Location: string().default('').required().label('Location'),
    BusinessName: string().default('').required().label('Business Name'),
    Email: string().email().default('').required().label('Email'),
    isAcceptTnc: boolean().default(false).oneOf([true]).required('Please indicate that you accept the terms and conditions to continue').label('Terms and Conditions'),
    Password: string().min(8).default('').required().label('Password'),
    ConfirmPassword: string().oneOf([ref('Password')], 'Confirm password does not match').required().default('').label('Confirm Password'),
    EmailOtp: string().required().min(6).label('One-Time-Passcode')
    // BusinessWebsiteUrl: string().url("Please ensure the url contains 'http://' or 'https://").default('').label('Business Website URL'),
})

export const consultantRegistrationFormFieldsSchema: SchemaOf<ConsultantRegistrationFormFields> = object({
    Email: string().email().default('').label('Email'),
    Password: string().default('').label('Password'),
    Name: string().default('').required().label('Name'),
    PhoneNumber: string().default('').required().label('Phone Number'),
    CountryId: string().default('').required().label('Country'),
    Location: string().default('').required().label('Location'),
    SkinMasterclassCertificationNumber: string().default('').label('Skin Masterclass Certification Number'),
    BusinessName: string().required().default('').label('Business Name'),
    BusinessWebsiteUrl: string().url("Please ensure the url contains 'http://' or 'https://").default('').label('Business Website URL'),
})

export const consultantRegistrationAuthFormFieldsSchema = object({
    Email: string().email().default('').required().label('Email'),
    Password: string().min(8).default('').required().label('Password'),
    isAcceptTnc: boolean().default(false).oneOf([true]).required('Please indicate that you accept the terms and conditions to continue').label('Terms and Conditions')
})

export const resetPasswordRequestFormFieldsSchema: SchemaOf<ResetPasswordRequestFormFields> = object({
    email: string().email().required().label('Email')
})

export const completeResetPasswordRequestFormFieldsSchema: SchemaOf<CompleteResetPasswordRequestFormFields> = object({
    email: string().default('').email().required().label('Email'),
    resetToken: string().default('').required().label('Reset Token'),
    newPassword: string().min(8).default('').required().label('New Password'),
    confirmNewPassword: string().min(8).default('').required().label('Confirm New Password').oneOf([ref('newPassword')], 'Your passwords do not match.')
})