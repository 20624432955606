import { useAppointmentStore } from "@/modules/proportal/store";
import { NavigationGuard } from "vue-router";

export const appointmentMustExist: NavigationGuard = async (to, from) => {
    const appointmentId = to.params['appointmentId'] as string;
    if (!appointmentId) return;

    const store = useAppointmentStore();
    await store.loadById(appointmentId);
    return !!store.appointmentRecord;
}