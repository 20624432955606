import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";
import { useErrorHandling } from "@/modules/core/composables";
import { 
    ConsultationRecord, 
    AppointmentDateGroupRecord, 
    AppointmentRecord,
    CreateFollowUpAppointmentRequest
} from "../types";
import { APIResponse } from "@/modules/core/types";
import { RouteLocationRaw } from "vue-router";
import { useCommonDataStore } from "./proportal";
import { usePortalConsultationsStore } from "./consultations";
import { router } from "../router";
import { usePortalAuthStore, useOrganisationStore } from "@/modules/proportal/store";

export const usePortalConsultationStore = defineStore('portalConsultation', {
    state: () => ({
        currentConsultationId: '',
        lastvisitedUrl: '',
        previousRoute: {} as RouteLocationRaw,
        consultation: {} as ConsultationRecord,
        appointments: [] as Array<AppointmentDateGroupRecord>,
        isBusyDeleting: false
    }),
    getters: {
        // currentConsultation: state => usePortalConsultationsStore().consultations.find(x => x.consultation.id === state.currentConsultationId),
        customerTerminologyName: () => useOrganisationStore().customerTerminology?.name,
        currentConsultation: state => state.consultation,
        consultationStatuses: () => useCommonDataStore().consultationStatuses,
        consultationMethods: () => useCommonDataStore().consultationMethods,
        learningNotes(): string {
            return this.currentConsultation.consultation?.learningNotes ?? ''
        },
        getClientId: (state) => {
            return state.consultation.consultation?.clientId ?? ''
        },
        getDownloadReportUrl: (state) => {
            return (consultationId: string) => {
                return `${endpoints.CONSULTATIONS}/${consultationId}/final-reports/download`;
            }
        }
    },
    actions: {
        async loadById (consultationId: string) {
            const consultationsStore = usePortalConsultationsStore();
            // await consultationsStore.getConsultations(false);
            
            if (!consultationsStore.consultations.find(x => x.consultation.id === consultationId)) return;
            this.currentConsultationId = consultationId;
        },
        updateConsultationRecord (record: ConsultationRecord) {
            this.consultation = record;
        },
        async getConsultation(consultationId: string) {
            // console.log('/consultation/:id API call')
            const request = axios.get<APIResponse<ConsultationRecord>>(`${endpoints.CONSULTATIONS}/${consultationId}`);
            const response = await useErrorHandling(request);
            this.consultation = response.data.data;
            this.currentConsultationId = response.data.data.consultation.id;
        },
        async updateLastVisitedUrl() {
            if (!this.currentConsultation) return;

            const currentPath = router.currentRoute.value.fullPath;

            // console.log('saved last visited URL', currentPath)

            const request = axios.put(
                `${endpoints.CONSULTATIONS}/${this.currentConsultation?.consultation?.id}`,
                { lastvisitedUrl: currentPath }
            )
            await useErrorHandling(request)
        },
        async completeConsultation () {
            if (!this.currentConsultation) return;

            const currentPath = router.currentRoute.value.fullPath;

            const request = axios.put(
                `${endpoints.CONSULTATIONS}/${this.currentConsultation.consultation.id}/complete`,
                { lastvisitedUrl: currentPath }
            )
            await useErrorHandling(request)
        },
        async getAppointmentsByConsultationId(timezoneOffset = false) {
            const request = axios.get<APIResponse<Array<AppointmentDateGroupRecord>>>(`${endpoints.APPOINTMENTS}`, {
                params: {
                    consultationId: this.currentConsultationId,
                    // timezoneOffset: !timezoneOffset ? undefined : new Date().getTimezoneOffset()
                }
            });

            const response = await useErrorHandling(request);
            this.appointments = response.data.data;
        },
        async createFollowUpAppointment(data: CreateFollowUpAppointmentRequest) {
            const request = axios.post<APIResponse<AppointmentRecord>>(
                `${endpoints.APPOINTMENTS}`,
                data
            );
            await useErrorHandling(request);
        },
        async editFollowUpAppointment(data: CreateFollowUpAppointmentRequest, appointmendId: string) {
            const request = axios.put<APIResponse<AppointmentRecord>>(
                `${endpoints.APPOINTMENTS}/${appointmendId}`,
                data
            );
            await useErrorHandling(request);
        },
        async deleteFollowUpAppointment(appointmentId: string) {
            this.isBusyDeleting = true;
            const request = axios.delete<APIResponse<AppointmentRecord>>(
                `${endpoints.APPOINTMENTS}/${appointmentId}`,
            );
            await useErrorHandling(request);
            this.isBusyDeleting = false;
        },
    }
})