import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";
import { defineStore } from "pinia";
import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import { ConsultationSkinNeedRecord, UpdateConsultationSkinNeedRequest } from "../types";

export const usePortalConsultationSkinNeedsStore = defineStore('portalConsultationSkinNeeds', {
    state: () => ({
        skinNeeds: [] as Array<ConsultationSkinNeedRecord>,
        currentSkinNeedId: ''
    }),
    getters: {
        skinNeedById: state => (skinNeedId: string) => state.skinNeeds.find(x => x.id === skinNeedId),
    },
    actions: {
        async getSkinNeeds(consultationId: string) {
            const request = axios.get<APIResponse<Array<ConsultationSkinNeedRecord>>>(`${endpoints.CONSULTATIONS}/${consultationId}/skin-needs`);
            const response = await useErrorHandling(request);
            this.skinNeeds = response.data.data;
        },
        async updateSkinNeed(consultationId: string, skinNeedId: string, data: UpdateConsultationSkinNeedRequest) {
            const request = axios.put<APIResponse<ConsultationSkinNeedRecord>>(
                `${endpoints.CONSULTATIONS}/${consultationId}/skin-needs/${skinNeedId}`,
                data
            );
            const response = await useErrorHandling(request);
            const skinNeedRecord = this.skinNeeds.find(x => x.id === response.data.data.id);

            if(!skinNeedRecord) return;

            skinNeedRecord.skinNeed = response.data.data.skinNeed;
        }
    }
})