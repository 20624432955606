import { useClientStore } from "@/modules/proportal/store";
import { NavigationGuard } from "vue-router";

export const clientMustExist: NavigationGuard = async (to, from) => {
    const clientId = to.params['clientId'] as string;
    if (!clientId) return false;

    const store = useClientStore();
    await store.loadById(clientId);

    return !!store.clientRecord;
}