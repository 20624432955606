import { APIResponse, PaginatedData } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { useErrorHandling, useNotifications } from "@/modules/core/composables";
import { timer } from "@/modules/core/utilities";
import type { AddMemberFormField } from "../components/settings/members/types";
import { useOrganisationStore } from "@/modules/proportal/store";
import type { PortalConsultantMember } from "@/modules/proportal/types";

const notifications = useNotifications();

export const usePortalSettingsMembersStore = defineStore('portalSettingsMembers', {
    state: () => ({
        filters: {
            searchTerm: '',
            pageNumber: 1,
            pageSize: 10
        },
        members: null as PaginatedData<PortalConsultantMember>|null,
        isBusy: {
            members: false,
            paginating: false,
            deleting: false,
            inviting: false,
        },
        isError: {
            members: false,
            paginating: false,
            deleting: false,
            inviting: false,
        }
    }),
    getters: {
        selectedOrganisationId: () => useOrganisationStore().selectedOrganisationId,
        isMembersQuotaExceeded: (state) => {
            if(state.members) {
                return state.members.count > useOrganisationStore().maximumConsultants;
            }
            return false;
        },
        isMembersQuotaReached: (state) => {
            if(state.members) {
                return state.members.count === useOrganisationStore().maximumConsultants;
            }
            return false;
        }
    },
    actions: {
        async loadMembers(isFalseBusyDisabled = false) {
            this.isBusy.members = true;
            const request = axios.get<APIResponse<PaginatedData<PortalConsultantMember>>>(`${endpoints.ORGANISATIONS}/${this.selectedOrganisationId}/members`, { 
                params: this.filters
            });
            const response = await useErrorHandling(request);

            if(response.data && response.data.data) {
                this.members = response.data.data;
            }
            if(!isFalseBusyDisabled) {
                this.isBusy.members = false;
            }
        },
        async addMember(member: AddMemberFormField) {
            this.isBusy.inviting = true;
            this.isError.inviting = false;
            const request = axios.post<APIResponse<PortalConsultantMember>>(`${endpoints.ORGANISATIONS}/${this.selectedOrganisationId}/members`, member);
            const response = await useErrorHandling(request, undefined, true);

            if(response.status === 200) {
                if(this.members) {
                    const currentMembersCount = this.members.count;
                    for (let i = 0; i < 3; i++) {
                        await this.loadMembers(true);
                        if(this.members.count !== currentMembersCount) {
                            this.isBusy.members = false;
                            break;
                        }
                        await timer(1500);
                    }
        
                    notifications.showToast({
                        message: 'Member added successfully',
                        type: 'success'
                    })
                }
            } else if(response.status >= 400) {
                this.isError.inviting = true;
            }
            this.isBusy.inviting = false;
        },
        async updateMember(memberId: string, isAdmin?: boolean, isDisabled?: boolean) {
            const request = axios.put<APIResponse<string>>(`${endpoints.ORGANISATIONS}/${this.selectedOrganisationId}/members/${memberId}`, { 
                isAdmin, isDisabled 
            });
            const response = await useErrorHandling(request, undefined, true);
            if(response.status === 200) {
                notifications.showToast({
                    message: `Member's ${isAdmin ? 'role' : isDisabled ? 'status' : ''} updated successfully`,
                    type: 'success'
                })
            }
            return response;
        },
        async deleteMember(memberId: string) {
            this.isBusy.deleting = true;
            const request = axios.delete<APIResponse<string>>(`${endpoints.ORGANISATIONS}/${this.selectedOrganisationId}/members/${memberId}`);
            const response = await useErrorHandling(request, undefined, true);

            if(response.status === 200) {
                if(this.members) {
                    const currentMembersCount = this.members.count;
                    for (let i = 0; i < 3; i++) {
                        await this.loadMembers(true);
                        if(this.members.count !== currentMembersCount) {
                            this.isBusy.members = false;
                            break;
                        }
                        await timer(1500);
                    }
        
                    notifications.showToast({
                        message: 'Member deleted successfully',
                        type: 'success'
                    })
                }
            }

            this.isBusy.deleting = false;
            
            // this.loadMembers();
        },
    }
})