import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { useErrorHandling, useNotifications } from "@/modules/core/composables";
import { timer } from "@/modules/core/utilities";
import { useOrganisationStore, usePortalAuthStore } from "@/modules/proportal/store";
import type { BillingsRecord, SubscriptionHistoryItem } from "@/modules/proportal/types";

const notifications = useNotifications();

export const usePortalSettingsBillingStore = defineStore('portalSettingsBilling', {
    state: () => ({
        isPaymentSuccess: false,
        isPlanSwitchSuccess: false,
        subscriptionStatusPollingCount: 0,
        filters: {
            pageNumber: 1,
            pageSize: 10
        },
        billingsRecord: {} as BillingsRecord,
        isBusy: {
            billings: false,
            paginating: false,
            upgradingToAnnualPlan: false
        },
    }),
    getters: {
        activePlanDisplayName: (state) => {
            if(state.billingsRecord.isOnFreeTrial) {
                return `${state.billingsRecord.activePlanName} Plan - Trial`;
            }
            return `${state.billingsRecord.activePlanName} Plan`;
        }
    },
    actions: {
        async loadSubscriptionStatus() {
            const request = axios.get<APIResponse>(`${endpoints.CONSULTANTS}/current/statuses`);
            const response = await useErrorHandling(request);
            const authStore = usePortalAuthStore();

            if(response.data) {
                if(response.data.isSuccess) {
                    await authStore.getCurrentUser();
                }
                this.isPaymentSuccess = response.data.isSuccess;
            }

            this.subscriptionStatusPollingCount++;
        },
        async loadBillings(isBusy = true, isFalseBusyDisabled = false) {
            this.isBusy.billings = isBusy;
            const organisationId = useOrganisationStore().selectedOrganisationId;
            const request = axios.get<APIResponse<BillingsRecord>>(`${endpoints.ORGANISATIONS}/${organisationId}/billings`, { 
                params: {
                    invoicePageNumber: this.filters.pageNumber,
                    invoicePageSize: this.filters.pageSize
                }
            });
            const response = await useErrorHandling(request);

            if(response.data && response.data.data) {
                this.billingsRecord = response.data.data;
            }
            if(!isFalseBusyDisabled) {
                this.isBusy.billings = false;
            }
        },
        async onNextPage() {
            if(!this.billingsRecord.subscriptionHistoryItems) return;
            if(this.filters.pageNumber >= this.billingsRecord.subscriptionHistoryItems.totalPages) return;
            this.filters.pageNumber++;
            this.isBusy.paginating = true;
            await this.loadBillings(false);
            this.isBusy.paginating = false;
        },
        async onPrevPage() {
            if(this.filters.pageNumber <= 1) return;
            this.filters.pageNumber--;
            this.isBusy.paginating = true;
            await this.loadBillings(false);
            this.isBusy.paginating = false;
        },
        async onPageChange(pageNumber: number) {
            this.filters.pageNumber = pageNumber;
            this.isBusy.paginating = true;
            await this.loadBillings(false);
            this.isBusy.paginating = false;
        },
        async onResetFilter() {
            this.filters = {
                pageNumber: 1,
                pageSize: 12
            }
        },
        async switchToAnnualPlan() {
            this.isPlanSwitchSuccess = false;
            this.isBusy.upgradingToAnnualPlan = true;
            const request = axios.post<APIResponse<string>>(`${endpoints.ORGANISATIONS}/${useOrganisationStore().selectedOrganisationId}/subscriptions/upgrades/yearly`);
            const response = await useErrorHandling(request, undefined, true);
            if(response.status >= 400) {
                this.isBusy.upgradingToAnnualPlan = false;
            }
            if(response.status === 200) {
                const currentSubcriptionItemsCount = this.billingsRecord.subscriptionHistoryItems.count;

                for (let i = 0; i < 10; i++) {
                    await this.loadBillings(true, true);
                    if(this.billingsRecord.subscriptionHistoryItems.count !== currentSubcriptionItemsCount) {
                        this.isBusy.billings = false;
                        break;
                    }
                    await timer(1500);
                }
                this.isBusy.billings = false;
                this.isBusy.upgradingToAnnualPlan = false;
                this.isPlanSwitchSuccess = true;
                this.onResetFilter();
                notifications.showToast({
                    type: 'success',
                    message: 'Plan upgraded successfully.'
                })
            }
        },
        getPlanDisplayNameFromHistoryItem(historyItem: SubscriptionHistoryItem) {
            if(historyItem.isFreeTrial) {
                return `${historyItem.item.name} Plan (Trial)`;
            }
            return `${historyItem.item.name} Plan (${historyItem.recurringInterval === 'month' ? 'Monthly' : 'Yearly'})`;
        },
        async endTrial() {
            this.isPlanSwitchSuccess = false;
            this.isBusy.upgradingToAnnualPlan = true;
            const request = axios.post<APIResponse<string>>(`${endpoints.ORGANISATIONS}/${useOrganisationStore().selectedOrganisationId}/subscriptions/upgrades/end-trial`);

            const response = await useErrorHandling(request, undefined, true);
            if(response.status >= 400) {
                this.isBusy.upgradingToAnnualPlan = false;
            }
            if(response.status === 200) {
                const currentSubcriptionItemsCount = this.billingsRecord.subscriptionHistoryItems.count;
                for (let i = 0; i < 10; i++) {
                    await this.loadBillings(true, true);
                    if(this.billingsRecord.subscriptionHistoryItems.count !== currentSubcriptionItemsCount) {
                        this.isBusy.billings = false;
                        break;
                    }
                    await timer(1500);
                }
                this.isBusy.billings = false;
                this.isBusy.upgradingToAnnualPlan = false;
                this.isPlanSwitchSuccess = true;
                this.onResetFilter();
                notifications.showToast({
                    type: 'success',
                    message: 'Trial skipped successfully. This process will take some time to reflect in the system.'
                })
            }
        },
    }
})