import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "../endpoints";
import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse, PaginatedData } from "@/modules/core/types";
import { AnalyticsAppointmentsRecord } from "../types";
import { useAnalyticsStore } from './'

export const useAnalyticsAppointmentsStore = defineStore('portalAnalyticsAppointments', {
    state: () => ({
        isBusy: false,
        analyticsAppointmentsRecords: null as PaginatedData<AnalyticsAppointmentsRecord>|null
    }),
    getters: {
        filters: () => useAnalyticsStore().filters
    },
    actions: {
        async load() {
            this.isBusy = true;
            const request = axios.get<APIResponse<PaginatedData<AnalyticsAppointmentsRecord>>>(
                `${endpoints.ANALYTICS}/appointments`, { params: this.filters }
            );
            const response = await useErrorHandling(request);

            this.analyticsAppointmentsRecords = response.data.data;
            this.isBusy = false;
        },
    }
})