import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { AppointmentRecord, UpdateAppointmentRequest } from "../types";
import { useOrganisationStore, useAppointmentsStore } from "@/modules/proportal/store";

export const useAppointmentStore = defineStore('appointment', {
    state: () => ({
        appointmentId: '',
        appointmentRecord: null as AppointmentRecord|null
    }),
    getters: {
        customerTerminologyName: () => useOrganisationStore().customerTerminology?.name,
    },
    actions: {
        async loadById(appointmentId: string) {
            const request = axios.get<APIResponse<AppointmentRecord>>(
                `${endpoints.APPOINTMENTS}/${appointmentId}`
            );

            const response = await useErrorHandling(request);

            this.$patch({
                appointmentId,
                appointmentRecord: response.data.data
            })
        },
        async update(data: UpdateAppointmentRequest) {
            if (!this.appointmentId) return;

            const request = axios.put<APIResponse<AppointmentRecord>>(
                `${endpoints.APPOINTMENTS}/${this.appointmentId}`,
                data
            );

            const response = await useErrorHandling(request);

            this.$patch({
                appointmentRecord: response.data.data
            })
            useAppointmentsStore().update(this.appointmentId, response.data.data);
            // Update the appointments store, so that it can reflect on the calendar
            // const appointmentFromCollections = useAppointmentsStore().loadById(this.appointmentId);
            // console.log(appointmentFromCollections);
            // useAppointmentsStore().$patch({
            //     appointmentDateGroupRecords: 
            // })
        }
    }
})