import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { ConsultationAdditionalQuestionAnswerRecords, ConsultationPersonalHealthRecord, SkinCondition, UpdatePersonalHealthDetailsRequest } from "../types";
import { usePortalConsultationStore } from "./consultation";
import { usePortalConsultationsStore } from "./consultations";
import { ConsultationAdditionalQuestion } from "../types";

export const usePortalConsultationPersonalHealthStore = defineStore('portalConsultationPersonalHealth', {
    state: () => ({
        isReady: false,
        consultationSkinConditions: [] as Array<SkinCondition>,
        consultationAdditionalQuestionAnswerRecords: [] as Array<ConsultationAdditionalQuestionAnswerRecords>,
        additionalQuestions: [] as Array<ConsultationAdditionalQuestion>
    }),
    getters: {
        currentConsultation: () => {
            return usePortalConsultationStore().currentConsultation
        },
        formInitialValues (): Omit<UpdatePersonalHealthDetailsRequest, 'lastVisitedUrl'>|undefined {
            if (!this.currentConsultation) return;
            const { consultation } = this.currentConsultation;
            
            return {
                allergies: consultation.allergies ?? '',
                hasAllergies: consultation.hasAllergies,
                hasHealthConditions: consultation.hasHealthConditions,
                healthConditions: consultation.healthConditions ?? '',
                skinConditionIds: this.consultationSkinConditions.map(x => x.key),
                consultantAdditionalQuestionAnswers: this.consultationAdditionalQuestionAnswerRecords.map(x => {
                    return { 
                        consultantAdditionalQuestionId: x.consultantAdditionalQuestion.id,
                        answer: x.answer
                    }
                })
            }
        },
        getAdditionalQuestionLabel: (state) => {
            return (questionId: string) =>{
                const question = state.consultationAdditionalQuestionAnswerRecords.find(x => x.consultantAdditionalQuestion.id === questionId)
                return question?.consultantAdditionalQuestion.question
            }
        },
        getAnswerForAdditionalQuestion: (state) => {
            return (questionId: string) => {
                const item = state.consultationAdditionalQuestionAnswerRecords.find(x => x.consultantAdditionalQuestion.id === questionId)
                return item?.answer
            }
        }
    },
    actions: {
        async load () {
            if (!this.currentConsultation) return;

            const consultationId = this.currentConsultation.consultation.id;
            
            const request = axios.get<APIResponse<ConsultationPersonalHealthRecord>>(
                `${endpoints.CONSULTATIONS}/${consultationId}/personal-health`
            );
            // const additionalQuestionsRequest = axios.get<APIResponse<Array<ConsultationAdditionalQuestion>>>(`${endpoints.ADDITIONAL_QUESTIONS}`)

            this.isReady = false;

            const response = await useErrorHandling(request);
            const data = response.data.data;

            this.$patch({
                consultationSkinConditions: data.consultationSkinConditionRecord.consultationSkinConditions,
                consultationAdditionalQuestionAnswerRecords: data.consultationAdditionalQuestionAnswerRecords ?? [],
                // additionalQuestions: data.consultationAdditionalQuestionAnswerRecords.map(x => ({
                    
                // }))
            })

            this.isReady = true;
            // if (!this.currentConsultation) return;

            // const consultationId = this.currentConsultation.consultation.id;
            
            // const personalHealthDataRequest = axios.get<APIResponse<ConsultationPersonalHealthRecord>>(
            //     `${endpoints.CONSULTATIONS}/${consultationId}/personal-health`
            // );
            // const additionalQuestionsRequest = axios.get<APIResponse<Array<ConsultationAdditionalQuestion>>>(`${endpoints.ADDITIONAL_QUESTIONS}`)

            // this.isReady = false;

            // try {
            //     const [
            //         personalHealthDataResponse,
            //         additionalQuestionsResponse
            //     ] = await Promise.all([useErrorHandling(personalHealthDataRequest), useErrorHandling(additionalQuestionsRequest)]);
    
            //     this.$patch({
            //         consultationSkinConditions: personalHealthDataResponse.data.data.consultationSkinConditionRecord.consultationSkinConditions,
            //         additionalQuestions: additionalQuestionsResponse.data.data
            //     })
            //     this.consultationAdditionalQuestionAnswerRecords = personalHealthDataResponse.data.data.consultationAdditionalQuestionAnswerRecords
            // } finally {
            //     this.isReady = true;
            // }
        },
        async update (data: UpdatePersonalHealthDetailsRequest) {
            if (!this.currentConsultation) return;

            const consultationId = this.currentConsultation.consultation.id;
            
            const request = axios.put<APIResponse<ConsultationPersonalHealthRecord>>(
                `${endpoints.CONSULTATIONS}/${consultationId}/personal-health`,
                data
            );
            const response = await useErrorHandling(request);            

            const { consultation, consultationSkinConditions} = response.data.data.consultationSkinConditionRecord;

            this.consultationSkinConditions = consultationSkinConditions;
            usePortalConsultationsStore().updateConsultation(consultation);
        },
    }
})