import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";
import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse, PaginatedData } from "@/modules/core/types";
import { IngredientRecord } from "../types";

export const useIngredientsStore = defineStore('ingredients', {
    state: () => ({
        pages: [] as PaginatedData<IngredientRecord>[],
        filters: {
            searchTerm: '',
            pageNumber: 1,
            pageSize: 20,
            onlyConsumerIngredient: false
        },
        busyStates: {
            isLoading: false,
            isLoadingNextPage: false
        },
        listLastScrollY: 0
    }),
    getters: {
        ingredients: state => state.pages.flatMap(x => x.items)
    },
    actions:{
        async getIngredients (searchTerms = '', onlyConsumerIngredient = false) {
            this.$patch({
                filters: {
                    pageNumber: 1,
                    searchTerm: searchTerms,
                    onlyConsumerIngredient
                },
                pages: [],
                busyStates: {
                    isLoading: true
                }
            })
            try {
                const request = axios.get<APIResponse<PaginatedData<IngredientRecord>>>(
                    `${endpoints.INGREDIENTS}/paginated`,
                    { params: this.filters }
                );
                const response = await useErrorHandling(request)
                this.pages = [response.data.data];
            } finally {
                this.busyStates.isLoading = false;
            }
        },
        async loadNextPage () {
            this.$patch({
                filters: {
                    pageNumber: this.filters.pageNumber + 1,
                    onlyConsumerIngredient: this.filters.onlyConsumerIngredient
                },
                busyStates: {
                    isLoadingNextPage: true
                }
            })
            try {
                const request = axios.get<APIResponse<PaginatedData<IngredientRecord>>>(
                    `${endpoints.INGREDIENTS}/paginated`,
                    { params: this.filters }
                );
                const response = await useErrorHandling(request)
                this.pages.push(response.data.data);
            } finally {
                this.busyStates.isLoadingNextPage = false;
            }
        }
    }
})