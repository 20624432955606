import dayjs from "dayjs";
import { defineStore } from "pinia";
// import { usePortalAuthStore } from "./auth";

export const useAnalyticsStore = defineStore('portalAnalytics', {
    state: () => ({
        filters: {
            fromDateTime: dayjs().startOf('month').format('YYYY-MM-DD'),
            toDateTime: dayjs().endOf('month').format('YYYY-MM-DD'), 
            timeInterval: 'Lifetime',
            // consultantIds: [],
            consultantIds: [] as number[],
            pageNumber: 1,
            pageSize: 1
        },
    }),
    getters: {
        // customerTerminologyName: () => usePortalAuthStore().auth.consultant?.customerTerminology?.name,
        getPercentages: () => (values: Array<number>, count: number, customSum: number|undefined = 0) => {
            const sum = !customSum ? values.reduce((a, b) => a + b) : customSum;
            const percentage = (count / sum) * 100;

            if(!isNaN(percentage)) {
                if(percentage === 0) return '0%';
                if(percentage === 100) return '100%';
                return `${percentage.toFixed(1)}%`;
            }
            return '0%';
        }
    }
})