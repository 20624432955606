import { object, number, boolean, SchemaOf, string, mixed, date } from 'yup';
import { FollowUpConsultationBookingFormFields } from './types';
import { MaybeRef, computed, unref } from 'vue';

export const followUpAppointmentSchema: SchemaOf<FollowUpConsultationBookingFormFields> = object({
    durationPickerEnabled: boolean().default(false).label('Duration Enabled'),
    appointmentTypeId: string().required(),
    clientId: string().default('').label('Client'),
    startTime: mixed().required().label('Start Time'),
    clientStartDateTime: string().label('Client Start Datetime'),
    clientEndDateTime: string().label('Client Start Datetime'),
    endTime: mixed()
        .label('End Time')
        .when('durationPickerEnabled', {
            is: true,
            then: (schema) => schema.required(),
        }),
    notes: string().label('Notes'),
    duration: number()
        .label('Duration')
        .when('durationPickerEnabled', {
            is: true,
            then: (schema) => schema.min(1, 'Duration is a required field').required(),
        }),
    appointmentDate: date().nullable().required().label('Appointment Date'),
    consultationId: string().default(''),
});

export const useFollowUpAptSchema = (isClashing: MaybeRef<boolean>) => {
    return computed(() => {
        return object({
            ...followUpAppointmentSchema.fields,
            startTime: mixed()
                .required()
                .test('Start time should not clash', 'Your appointment time is clashing', () => {
                    return !unref(isClashing);
                })
                .label('Start Time'),
        });
    });
};
