import { defineStore } from "pinia";
import { APIResponse, PaginatedData } from "@/modules/core/types";
import { useErrorHandling } from "@/modules/core/composables";
import { IngredientRecord, ConsultantIngredientCreateAndEditRequest } from "../types";
import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";
import { router } from "../router";
import { useNotifications } from '@/modules/core/composables';

const notifications = useNotifications();

export const usePortalSettingsIngredientsStore = defineStore('portalSettingsIngredients', {
    state: () => ({
        isBusy: false,
        isFilteringBusy: false,
        isPageUpdatingBusy: false,
        isSavingBusy: false,
        filters: {
            name: '',
            pageNumber: 1,
            pageSize: 10
        },
        paginatedResults: null as PaginatedData<IngredientRecord> | null
    }),
    actions: {
        async createConsultantIngredient(data: ConsultantIngredientCreateAndEditRequest) {
            this.isSavingBusy = true;
            const request = axios.post<APIResponse<PaginatedData<IngredientRecord>>>(
                `${endpoints.CONSULTANT_INGREDIENTS}`,
                data
            )
            const response = await useErrorHandling(request);
            this.isSavingBusy = false;
            notifications.showToast({
                message: 'Ingredient has been created successfully',
                type: 'success'
            })
            this.paginatedResults = response.data.data;
        },
        async updateConsultantIngredient(ingredientId: string, data: ConsultantIngredientCreateAndEditRequest) {
            this.isSavingBusy = true;
            const request = axios.put<APIResponse<IngredientRecord>>(
                `${endpoints.CONSULTANT_INGREDIENTS}/${ingredientId}`,
                data
            )
            
            try {
                const response = await useErrorHandling(request);
                const index = this.paginatedResults?.items.findIndex(x => x.id === ingredientId) ?? -1;

                if (index === -1) return;

                if (this.paginatedResults) {
                    this.paginatedResults.items[index] = response.data.data;
                }
            } finally {
                this.isSavingBusy = false;
                notifications.showToast({
                    message: 'Ingredient details has been updated successfully',
                    type: 'success'
                })
            }
        },
        async deleteConsultantIngredient(ingredientId: string) {
            this.isSavingBusy = true;
            const request = axios.delete<APIResponse<IngredientRecord>>
            (`${endpoints.CONSULTANT_INGREDIENTS}/${ingredientId}`)
            await useErrorHandling(request);
            this.isSavingBusy = false;

            this.getIngredients(false, 'delete');
        },
        async getIngredients(routerPush: boolean = true, action: string = '') {
            this.isBusy = true;

            if(this.paginatedResults && this.paginatedResults.items.length > 0) {
                if(this.filters.pageNumber === this.paginatedResults.totalPages) {
                    if(this.filters.pageNumber > 1) {
                        if(this.paginatedResults.items.length === 1) {
                            if(action === 'delete') {
                                this.filters.pageNumber--;
                                routerPush = true;
                            }
                        } else if(this.paginatedResults.items.length === this.filters.pageSize) {
                            if(action === 'create') {
                                this.filters.pageNumber++;
                                routerPush = true;
                            }
                        }
                    }
                }
            }

            const { name, pageNumber, pageSize } = this.filters;
            const request = axios.get<APIResponse<PaginatedData<IngredientRecord>>>
                (`${endpoints.CONSULTANT_INGREDIENTS}`,
                { params: { name, pageNumber, pageSize }})
            const response = await useErrorHandling(request);
            
            this.paginatedResults = response.data.data;
            this.isBusy = false;
            this.isFilteringBusy = false;
            
            if(routerPush) {
                router.push({
                    name: 'portalSettingsIngredients',
                    query: {
                        name: this.filters.name,
                        pageNumber: this.filters.pageNumber
                    }
                })
            }
        },
        async onNextPage() {
            if(!this.paginatedResults) return;
            if(this.filters.pageNumber >= this.paginatedResults.totalPages) return;
            this.filters.pageNumber++;
            await this.getIngredients();
        },
        async onPrevPage() {
            if(this.filters.pageNumber <= 1) return;
            this.filters.pageNumber--;
            await this.getIngredients();
        },
        async onPageChange(pageNumber: number) {
            this.filters.pageNumber = pageNumber;
            await this.getIngredients();
        }
    }
})