import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";
import { defineStore } from "pinia";
import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import { useConsultationCustomIngredientsStore, usePortalConsultationStore } from '@/modules/proportal/store'
import { 
    ConsultationSuitableIngredientsRecord,
    CreateSuitableIngredientsRequest,
    IngredientRecord,
    ConsultationSuitableIngredientRecord
} from "../types";

export const usePortalConsultationSuitableIngredientsStore = defineStore('portalConsultationSuitableIngredients', {
    state: () => ({
        suitableIngredientsAndSkinMeasurement: [] as Array<ConsultationSuitableIngredientsRecord>,
        ingredientsDictionaryRecords: [] as Array<IngredientRecord>,
        isBusy: false,
        isCreatingCustomIngredientBusy: false,
        isSearchingIngredientBusy: false
    }),
    getters: {
        consultationId: () => usePortalConsultationStore().currentConsultationId,
        ingredientsForCheckboxes: state => (itemIndex: number, predicate: (value: ConsultationSuitableIngredientRecord) => boolean) => {
            const suitableIngredientRecords = state.suitableIngredientsAndSkinMeasurement[itemIndex].consultationSuitableIngredientRecords;

            const suitableIngredients = suitableIngredientRecords.filter(x => predicate(x));

            return suitableIngredients.map(x => ({
                ingredientId: x.ingredientRecord.id,
                isCustomIngredient: x.ingredientRecord.isCustomIngredient,
                isSelected: x.isSelected,
                name: x.ingredientRecord.displayName
            }))
        },
        getSuitableIngredientsAndSkinMeasurementWithoutCustomIngredients: state => (itemIndex: number) => {
            const suitableIngredientRecords = state.suitableIngredientsAndSkinMeasurement[itemIndex].consultationSuitableIngredientRecords;
            const withoutCustomIngredients = suitableIngredientRecords.filter(x => !x.ingredientRecord.isCustomIngredient);

            state.suitableIngredientsAndSkinMeasurement[itemIndex].consultationSuitableIngredientRecords = withoutCustomIngredients;
        },
    },
    actions: {
        async getSuitableIngredientAndSkinMeasurementData() {
            this.isBusy = true;

            const request = axios.get<APIResponse<Array<ConsultationSuitableIngredientsRecord>>>(`${endpoints.CONSULTATIONS}/${this.consultationId}/suitable-ingredients`);
            const response = await useErrorHandling(request);
            this.suitableIngredientsAndSkinMeasurement = response.data.data;

            this.isBusy = false;
        },
        async getSuitableIngredientAndSkinMeasurementDataRecomputed () {
            this.isBusy = true;

            const request = axios.get<APIResponse<Array<ConsultationSuitableIngredientsRecord>>>
            (`${endpoints.CONSULTATIONS}/${this.consultationId}/suitable-ingredients/recomputed`);
            const response = await useErrorHandling(request);
            this.suitableIngredientsAndSkinMeasurement = response.data.data;
            this.isBusy = false;
        },
        async createAndUpdateSuitableIngredients(data: CreateSuitableIngredientsRequest) {
            this.isBusy = true;
            const request = axios.post<APIResponse<Array<ConsultationSuitableIngredientsRecord>>>(
                `${endpoints.CONSULTATIONS}/${this.consultationId}/suitable-ingredients`,
                data
            );
            const response = await useErrorHandling(request);
            this.isBusy = false;

            this.suitableIngredientsAndSkinMeasurement = response.data.data;
            return response.data.data;
        },
        async getIngredientsDictionaryRecords(searchTerm: string, limit: number = 30) {
            // this.isSearchingIngredientBusy = true;
            this.ingredientsDictionaryRecords = [];
            const request = axios.get<APIResponse<Array<IngredientRecord>>>(`${endpoints.INGREDIENTS}`, {
                params: { searchTerm, limit }
            });
            const response = await useErrorHandling(request);
            this.isSearchingIngredientBusy = false;
            this.ingredientsDictionaryRecords = response.data.data;
        },

        /**
         * Creates custom ingredients for a skin measurement value. 
         * @param skinMeasurementValueId 
         * @param ingredientDisplayNames 
         * @returns all the ingredients for the given skin measurement value that includes the newly added ingredients
         */
        async createCustomIngredients(skinMeasurementValueId: string, ingredientDisplayNames: string[]) {
            const skinMeasurementValueIngredientsRecord = this.suitableIngredientsAndSkinMeasurement.find(x => x.skinMeasurementValueRecord.id === skinMeasurementValueId);
            if (!skinMeasurementValueIngredientsRecord) return;

            const getIngredients = () => skinMeasurementValueIngredientsRecord.consultationSuitableIngredientRecords.map(x => x.ingredientRecord);

            if (ingredientDisplayNames.length === 0) return getIngredients();

            // Get all the existing custom ingredients for this section
            const existingCustomIngredients = skinMeasurementValueIngredientsRecord.consultationSuitableIngredientRecords.filter(x => x.ingredientRecord.isCustomIngredient);
            const existingCustomIngredientNames = existingCustomIngredients.map(x => x.ingredientRecord.displayName);

            // Handle the removal of ingredients first
            const removedIngredientDisplayNames = existingCustomIngredientNames.filter(x => !ingredientDisplayNames.includes(x));
            skinMeasurementValueIngredientsRecord.consultationSuitableIngredientRecords = skinMeasurementValueIngredientsRecord.consultationSuitableIngredientRecords
                .filter(x => !removedIngredientDisplayNames.includes(x.ingredientRecord.displayName))

            // Get the newly added ingredient names
            const newCustomIngredientsDisplayNames = ingredientDisplayNames.filter(x => !existingCustomIngredientNames.includes(x));

            
            // Create the new custom ingredients
            const newIngredients = await useConsultationCustomIngredientsStore().createIngredients({
                skinMeasurementValueId,
                consultationCustomIngredients: newCustomIngredientsDisplayNames.map(name => {
                    return {
                        description: '',
                        name
                    }
                })
            });

            // Add them to the current record
            skinMeasurementValueIngredientsRecord.consultationSuitableIngredientRecords.push(
                ...newIngredients.map(ingredientRecord => {
                    return {
                        isSelected: true,
                        ingredientRecord
                    }
                })
            )

            return getIngredients()
        }
    }
})