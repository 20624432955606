import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import { useNotifications } from '@/modules/core/composables';
import endpoints from "../endpoints";
import { 
    ConsultationAdditionalQuestion, 
    AdditionalQuestionsCreateAndEditRequest, 
    UpdateReportSettingsRequest,
    Organisation
} from "@/modules/proportal/types";
import { usePortalAuthStore, useOrganisationStore } from "@/modules/proportal/store";

const notifications = useNotifications();

export const useSettingsConsultationSettingsStore = defineStore('settingsConsultationSettings', {
    state: () => ({
        isBusy: {
            loadAdditionalQuestions: false
        },
        additionalQuestions: [] as ConsultationAdditionalQuestion[]
    }),
    getters: {
        reportSettingsFormInitialValues () {
            const organisationStore = useOrganisationStore();
            if (!organisationStore.selectedOrganisation) return;

            return {
                reportPrimaryColourHexCode: organisationStore.selectedOrganisation.reportPrimaryColourHexCode ?? '#E3C3B4'
            }
        }
    },
    actions: {
        async loadAdditionalQuestions() {
            this.isBusy.loadAdditionalQuestions = true;
            const request = axios.get<APIResponse<ConsultationAdditionalQuestion[]>>(`${endpoints.ADDITIONAL_QUESTIONS}`)

            const response = await useErrorHandling(request); 
            this.additionalQuestions = response.data.data;
            this.isBusy.loadAdditionalQuestions = false;
        },
        async createAdditionalQuestions(form: AdditionalQuestionsCreateAndEditRequest) {
            let data = Object.keys(form).length ? form : { additionalQuestions: []};

            const request = axios.post<APIResponse<ConsultationAdditionalQuestion[]>>(`${endpoints.ADDITIONAL_QUESTIONS}`, data);
            const response = await useErrorHandling(request);

            this.additionalQuestions = response.data.data;

            notifications.showToast({
                message: 'Additional questions saved successfully',
                type: 'success'
            });
        },
        async updateReportSettings (data: UpdateReportSettingsRequest) {
            const authStore = usePortalAuthStore();
            const organisationStore = useOrganisationStore();
            
            const request = axios.put<APIResponse<Organisation>>(
                `${endpoints.ORGANISATIONS}/${organisationStore.selectedOrganisationId}/report-settings`,
                data
            );

            const response = await useErrorHandling(request);

            if(authStore.auth.consultant) {
                authStore.auth.consultant.selectedOrganisation = response.data.data;
            }

            notifications.showToast({
                message: 'Report settings saved successfully',
                type: 'success'
            });
        }
    }
})