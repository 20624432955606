import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { AppointmentDateGroupRecord, AppointmentRecord, CreateAppointmentRequest } from "../types";
import dayjs from 'dayjs';
import { getAppointments } from "../api";

const startOfMonth = dayjs().startOf('month').toDate();
const endOfMonth = dayjs().endOf('month').toDate();

export const useAppointmentsStore = defineStore('appointments', {
    state: () => ({
        filters: {
            fromDateTime: startOfMonth,
            toDateTime: endOfMonth
        },
        appointmentDateGroupRecords: [] as Array<AppointmentDateGroupRecord>
    }),
    getters: {
        getAppointmentDateGroupIndexById: (state) => (appointmentId: string) => {
            return state.appointmentDateGroupRecords.findIndex(x => {
                return x.appointmentRecords.some(y => y.appointment.id === appointmentId);
            })
        },
        getAppointmentIndexById: (state) => (appointmentId: string) => {
            const appointmentDateGroupIndex = state.appointmentDateGroupRecords.findIndex(x => {
                return x.appointmentRecords.some(y => y.appointment.id === appointmentId);
            });
            return state.appointmentDateGroupRecords[appointmentDateGroupIndex].appointmentRecords.findIndex(x => x.appointment.id === appointmentId);
        },
        loadById: (state) => (appointmentId: string) => {
            // return state.appointmentDateGroupRecords[appointmentDateGroupIndex].appointmentRecords.find(x => x.appointment.id === appointmentId);
            return state.appointmentDateGroupRecords.find(x => {
                return x.appointmentRecords.some(y => y.appointment.id === appointmentId);
            })?.appointmentRecords.find(x => x.appointment.id === appointmentId)
        }
    },
    actions: {
        async load (fromDateTime = startOfMonth, toDateTime = endOfMonth, timezoneOffset = false) {
            this.$patch({
                filters: { fromDateTime, toDateTime }
            })

            const response = await getAppointments(this.filters.fromDateTime, this.filters.toDateTime)
            
            this.$patch({
                appointmentDateGroupRecords: response.data
            })
        },
        async create (data: CreateAppointmentRequest) {
            // console.log('data appointment', data)
            const request = axios.post<APIResponse<AppointmentRecord>>(
                `${endpoints.APPOINTMENTS}`,
                data
            );

            await useErrorHandling(request);

            this.load(this.filters.fromDateTime, this.filters.toDateTime);
        },
        async delete(appointmentId: string) {
            const request = axios.delete<APIResponse<AppointmentRecord>>(
                `${endpoints.APPOINTMENTS}/${appointmentId}`
            );

            await useErrorHandling(request);

            this.load(this.filters.fromDateTime, this.filters.toDateTime);
        },
        async update(appointmentId: string, newAppointmentRecord: AppointmentRecord) {
            const appointmentDateGroupIndex = this.getAppointmentDateGroupIndexById(appointmentId);
            const appointmentIndex = this.getAppointmentIndexById(appointmentId); 
            this.appointmentDateGroupRecords[appointmentDateGroupIndex].appointmentRecords[appointmentIndex] = newAppointmentRecord;
        }
    }
})