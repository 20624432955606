import { useOrganisationStore, usePortalAuthStore } from "@/modules/proportal/store";
import { NavigationGuard } from "vue-router";

export const organisationMustActive: NavigationGuard = async (to, from, next) => {
    if(!useOrganisationStore().selectedOrganisation) {
        next('/');
    } else if(useOrganisationStore().hasActiveSubscription) {
        next();
    } else {
        next({ name: 'portalSettingsBillingPlans' });
    }
}

export const organisationMustHaveSubscribedBefore: NavigationGuard = async(to, from, next) => {
    if(!useOrganisationStore().selectedOrganisation) {
        next('/');
    } else if(useOrganisationStore().hasAnySubscription) {
        next();
    } else {
        next({ name: 'portalSettingsBillingPlans' });
    }
}

export const memberMustBeAdminOrOwner: NavigationGuard = async(to, from, next) => {
    if(usePortalAuthStore().isMember) {
        next({ name: 'accountDetails' })
    } else {
        next();
    }
}
