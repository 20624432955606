import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "../endpoints";
import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import { usePortalAuthStore } from "./auth";
import { AnalyticsClientsRecord } from "../types";
import { useAnalyticsStore, useOrganisationStore } from './'

export const useAnalyticsClientsStore = defineStore('portalAnalyticsClients', {
    state: () => ({
        isBusy: false,
        analyticsClientsRecord: null as AnalyticsClientsRecord|null,
    }),
    getters: {
        clientGenderAnalysisRecords: (state) => state.analyticsClientsRecord?.clientGenderAnalysisRecords,
        clientAgeAnalysisRecords: (state) => state.analyticsClientsRecord?.clientAgeAnalysisRecords,
        clientActivityAnalysisRecords: (state) => state.analyticsClientsRecord?.clientActivityAnalysisRecords,
        clientAcquisitionAnalysisRecords: (state) => state.analyticsClientsRecord?.clientAcquisitionAnalysisRecords,
        customerTerminology: () => useOrganisationStore().customerTerminology,
        filters: () => useAnalyticsStore().filters
    }, 
    actions: {
        async load() {
            this.isBusy = true;
            const request = axios.get<APIResponse<AnalyticsClientsRecord>>(
                `${endpoints.ANALYTICS}/clients`, { params: this.filters }
            );
            const response = await useErrorHandling(request);
            this.analyticsClientsRecord = response.data.data;

            this.isBusy = false;
        },
        async onNextPage() {
            if(!this.analyticsClientsRecord) return;
            if(this.analyticsClientsRecord && !this.analyticsClientsRecord.clientAcquisitionAnalysisRecords) return;

            if(this.filters.pageNumber >= this.analyticsClientsRecord.clientAcquisitionAnalysisRecords.totalPages) return;

            this.filters.pageNumber++;
            await this.load();
        },
        async onPrevPage() {
            if(this.filters.pageNumber <= 1) return;
            this.filters.pageNumber--;
            await this.load();
        },
        async onPageChange(pageNumber: number) {
            this.filters.pageNumber = pageNumber;
            await this.load();
        },
    }
})