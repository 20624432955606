import { defineStore } from "pinia";
import { APIResponse } from "@/modules/core/types";
import { useNotifications, useErrorHandling } from "@/modules/core/composables";
import { usePortalConsultationStore } from "@/modules/proportal/store";
import type { ClinicalTreatmentRecord } from "../types";
import type { ClinicalTreatmentFormFieldsNew } from "../components/consultation/step-new-skincare-routine/types";
import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";

const notifications = useNotifications();

export const usePortalConsultationClinicalTreatmentsStore = defineStore('portalConsultationClinicalTreatments', {
    state: () => ({
        isBusy: {
            loading: false,
            deleting: false
        },
        consultationClinicalTreatments: [] as ClinicalTreatmentRecord[]
    }),
    getters: {
        consultationId: () => usePortalConsultationStore().currentConsultationId,
    },
    actions: {
        async load() {
            this.isBusy.loading = true;

            const request = axios.get<APIResponse<ClinicalTreatmentRecord[]>>(`${endpoints.CONSULTATIONS}/${this.consultationId}/clinical-treatments`);
            const response = await useErrorHandling(request);

            this.consultationClinicalTreatments = response.data.data;

            this.isBusy.loading = false;
        },
        async add(data: ClinicalTreatmentFormFieldsNew) {
            this.isBusy.loading = true;

            try {
                const request = axios.post<APIResponse<ClinicalTreatmentRecord[]>>(`${endpoints.CONSULTATIONS}/${this.consultationId}/clinical-treatments`, data);
                const response = await useErrorHandling(request);
                this.consultationClinicalTreatments = response.data.data;
            } catch(err) {

            } finally {
                this.isBusy.loading = false;
            }

            // if(response.status === 200) {
            //     this.consultationClinicalTreatments = response.data.data;
            // }
        },
        async delete(id: string) {
            this.isBusy.deleting = true;

            const request = axios.delete<APIResponse<ClinicalTreatmentRecord[]>>(`${endpoints.CONSULTATIONS}/${this.consultationId}/clinical-treatments/${id}`);
            const response = await useErrorHandling(request);

            this.consultationClinicalTreatments = response.data.data;

            notifications.showToast({
                message: 'Treatment has been deleted from the consultation successfully',
                type: 'success'
            })

            this.isBusy.deleting = false;
        }
    }
})