import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { router } from "../router";
import { ConsultationFaceZoneSkinMeasurementRecord, SingleFaceZoneSkinMeasurement, SingleFaceZoneSkinMeasurementRequest, SkinMeasurementSection } from "../types";
import { usePortalConsultationStore } from "./consultation";
// import { usePortalConsultationsStore } from "./consultations";
import { useCommonDataStore } from "./proportal";

export const usePortalConsultationSkinMappingStore = defineStore('portalConsultationSkinMapping', {
    state: () => ({
        measurementList: [] as Array<SingleFaceZoneSkinMeasurement>,
        currentSkinMeasurementSectionId: '',
        busyUpdatingFaceZone: false
    }),
    getters: {
        skinToneSkinMeasurementValueId(): string {
            return this.currentConsultation?.consultation.skinToneSkinMeasurementValueId ?? ''
        },
        skinMeasurementSections: () => useCommonDataStore().skinMeasurementSections,
        currentConsultation: () => usePortalConsultationStore().currentConsultation,
        faceZoneByCode: () => (faceZoneCode: string) => {
            return useCommonDataStore().faceZones.find(x => x.name === faceZoneCode);
        },
        currentSkinMeasurementSection(state) {
            const sections = useCommonDataStore().skinMeasurementSections;
            return sections.find(x => x.key === state.currentSkinMeasurementSectionId) ?? sections[0];
        },
        nextSkinMeasurementSection(): SkinMeasurementSection {
            const sections = useCommonDataStore().skinMeasurementSections;
            const index = sections.findIndex(x => x.key === this.currentSkinMeasurementSection.key);
            return sections[index + 1];
        },
        prevSkinMeasurementSection(): SkinMeasurementSection {
            const sections = useCommonDataStore().skinMeasurementSections;
            const index = sections.findIndex(x => x.key === this.currentSkinMeasurementSection.key);
            return sections[index - 1];
        },
        firstSkinMapSectionId: state => {
            return useCommonDataStore().skinMeasurementSections[0].key;
        },
        secondSkinMapSectionId: state => {
            return useCommonDataStore().skinMeasurementSections[1].key;
        },
        /**
         * Returns the same data structure as the `measurementList` but each item's skin measurement
         * values are filtered to the current section
         */
        measurementListBySectionId(state) {
            return (skinMeasurementSectionId: string) => {
                const currSection = this.skinMeasurementSections.find(x => x.key === skinMeasurementSectionId);
                if (!currSection) return [];

                const commonDataStore = useCommonDataStore();
                const section = commonDataStore.skinMeasurementSections.find(x => x.key === currSection.key);
                if (!section) return [];

                const sectionMeasurementValueIds = section.skinMeasurementValues.map(x => x.key);

                // console.log(state.measurementList)

                return state.measurementList
                    .map(x => {
                        return {
                            ...x,
                            skinMeasurementValues: x.skinMeasurementValues.filter(y => {
                                return sectionMeasurementValueIds.includes(y.key)
                            })
                        }
                    })
                    .filter(x => x.skinMeasurementValues.length > 0)
            }
        },
        mappedZones(state) {
            return (skinMeasurementSectionId: string, conditionKey: string) => {
                const arr = this.measurementListBySectionId(skinMeasurementSectionId)
                let zoneArray = []

                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].skinMeasurementValues.filter(k => k.key === conditionKey).length > 0) {
                        // zoneArray.push(arr[i].faceZone.name)
                        zoneArray.push({
                            key: arr[i].faceZone.key,
                            name: arr[i].faceZone.name
                        })
                    }
                }
                return zoneArray
            }
        },
        currentSection(state) {
            return (skinMeasurementSectionId: string) => {
                const currSection = this.skinMeasurementSections.find(x => x.key === skinMeasurementSectionId);
                if (!currSection) return [];

                return currSection.skinMeasurementValues
            }
        },
        unmappedZones(state) {
            return (skinMeasurementSectionId: string) => {
                const currSection = this.skinMeasurementSections.find(x => x.key === skinMeasurementSectionId);
                if (!currSection) return [];

                const commonDataStore = useCommonDataStore();
                const section = commonDataStore.skinMeasurementSections.find(x => x.key === currSection.key);
                if (!section) return [];

                const sectionMeasurementValueIds = section.skinMeasurementValues.map(x => x.key);

                const faceZones = commonDataStore.faceZones
                let faceZoneNames = [] as any

                for (let i = 0; i < faceZones.length; i++) {
                    faceZoneNames.push(faceZones[i].name)
                }

                const measurementList = this.measurementListBySectionId(skinMeasurementSectionId)
                let existingZoneArray = [] as any
                
                for (let i = 0; i < sectionMeasurementValueIds.length; i++) {
                    let conditionKey = sectionMeasurementValueIds[i]
                    for (let j = 0; j < measurementList.length; j++) {
                        if (measurementList[j].skinMeasurementValues.filter(k => k.key === conditionKey).length > 0) {
                            if(!existingZoneArray.includes(measurementList[j].faceZone.name)){
                                existingZoneArray.push(measurementList[j].faceZone.name)
                            }
                        }
                    }
                }

                let diff = (a: any, b: any) => {
                    return a.filter(item => b.indexOf(item) === -1);
                }

                var diff1 = diff(faceZoneNames, existingZoneArray)
                var diff2 = diff(existingZoneArray, faceZoneNames)
                return [].concat(diff1, diff2)
            }
        },
        faceZoneMeasurementRequestList: (state): Array<SingleFaceZoneSkinMeasurementRequest> => {
            return state.measurementList.map(x => ({
                faceZoneId: x.faceZone.key,
                skinMeasurementValueIds: x.skinMeasurementValues.map(x => x.key)
            }))
        }
    },
    actions: {
        async loadMeasurementValues() {
            if (!this.currentConsultation || Object.keys(this.currentConsultation).length === 0) return;

            const consultationId = this.currentConsultation.consultation.id;

            const request = axios.get<APIResponse<ConsultationFaceZoneSkinMeasurementRecord>>(
                `${endpoints.CONSULTATIONS}/${consultationId}/face-zone-skin-measurements`
            );
            const response = await useErrorHandling(request);

            this.$patch({
                measurementList: response.data.data.consultationFaceZoneSkinMeasurements,
            })
        },
        async updateValuesForFaceZone(faceZoneCodes: string[], skinMeasurementValueIds: string[], isMultiple: boolean) {
            this.busyUpdatingFaceZone = true;
            if (!this.currentConsultation) return;
            if (!this.currentSkinMeasurementSection) return;

            const consultationId = this.currentConsultation.consultation.id;
            const faceZoneIds = faceZoneCodes
                .map(x => this.faceZoneByCode(x))
                .filter(Boolean)
                .map(x => x!.key);
            const currSectionPossibleMeasurementValues = this.currentSkinMeasurementSection.skinMeasurementValues.map(x => x.key);

            const skinMeasurementRequest = this.faceZoneMeasurementRequestList;

            // There's 2 kinds of cases to handle when it comes to updating the values for a facezone
            // 1. Existing face zone
            // 2. New face zone
            const allExistingFaceZoneIds = skinMeasurementRequest.map(x => x.faceZoneId);
            const untouchedFaceZoneIds = allExistingFaceZoneIds.filter(x => !faceZoneIds.includes(x));
            const existingFaceZonesToBeMeasured = allExistingFaceZoneIds.filter(x => faceZoneIds.includes(x));
            const newFaceZonesToBeMeasured = faceZoneIds.filter(x => !existingFaceZonesToBeMeasured.includes(x));

            const untouchedFaceZoneSkinMeasurements = skinMeasurementRequest.filter(x => untouchedFaceZoneIds.includes(x.faceZoneId));

            const existingFaceZoneSkinMeasurements: Array<SingleFaceZoneSkinMeasurementRequest> = skinMeasurementRequest
                .filter(x => existingFaceZonesToBeMeasured.includes(x.faceZoneId))
                .map(x => {
                    const existingValues = x.skinMeasurementValueIds;

                    // Filter out all the values of the current section
                    const existingValuesFiltered = isMultiple ? existingValues : existingValues.filter(x => !currSectionPossibleMeasurementValues.includes(x));

                    const newValues = [...existingValuesFiltered, ...skinMeasurementValueIds];

                    return {
                        faceZoneId: x.faceZoneId,
                        skinMeasurementValueIds: newValues
                    }
                })

            const newFaceZoneSkinMeasurements: Array<SingleFaceZoneSkinMeasurementRequest> = newFaceZonesToBeMeasured.map(x => {
                return {
                    faceZoneId: x,
                    skinMeasurementValueIds
                }
            })

            const faceZoneSkinMeasurements = [
                ...untouchedFaceZoneSkinMeasurements,
                ...existingFaceZoneSkinMeasurements,
                ...newFaceZoneSkinMeasurements
            ];

            const request = axios.put<APIResponse<ConsultationFaceZoneSkinMeasurementRecord>>(
                `${endpoints.CONSULTATIONS}/${consultationId}/face-zone-skin-measurements`,
                { faceZoneSkinMeasurements, lastVisitedUrl: window.location.pathname }
            );
            const response = await useErrorHandling(request);

            this.$patch({
                measurementList: response.data.data.consultationFaceZoneSkinMeasurements,
            })

            this.busyUpdatingFaceZone = false
        },
        async removeValuesForFaceZone(zones: any, conditionKey: any){
            const consultationId = this.currentConsultation.consultation.id;

            for(let i = 0; i < zones.length; i++){
                let skinValueArray = this.measurementList.find(x => x.faceZone.key === zones[i].key)

                const objWithIdIndex = skinValueArray?.skinMeasurementValues.findIndex((obj) => obj.key === conditionKey);
                if(typeof objWithIdIndex !== 'undefined'){
                    if (objWithIdIndex > -1) {
                        skinValueArray?.skinMeasurementValues.splice(objWithIdIndex, 1);
                      }
                }
            }

            const faceZoneSkinMeasurements = this.measurementList.map(x => {
                return {
                    faceZoneId: x.faceZone.key,
                    skinMeasurementValueIds: x.skinMeasurementValues.map(y => y.key)
                }
            })

            const request = axios.put<APIResponse<ConsultationFaceZoneSkinMeasurementRecord>>(
                `${endpoints.CONSULTATIONS}/${consultationId}/face-zone-skin-measurements`,
                { faceZoneSkinMeasurements, lastVisitedUrl: window.location.pathname }
            );
            const response = await useErrorHandling(request);

            this.$patch({
                measurementList: response.data.data.consultationFaceZoneSkinMeasurements,
            })

        },
        async updateSkinToneSkinMeasurement(skinToneSkinMeasurementValueId: string) {
            if (!this.currentConsultation) return;

            const lastVisitedUrl = router.currentRoute.value.fullPath;
            const consultationId = this.currentConsultation.consultation.id;
            const request = axios.put<APIResponse<ConsultationFaceZoneSkinMeasurementRecord>>(
                `${endpoints.CONSULTATIONS}/${consultationId}/skin-tone-skin-measurement`,
                { skinToneSkinMeasurementValueId, lastVisitedUrl }
            );
            const response = await useErrorHandling(request);

            usePortalConsultationStore().currentConsultation.consultation = response.data.data.consultation
        }
    }
})