import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { PaginatedArticle } from "../types";

export const useArticlesStore = defineStore('articles', {
    state: () => ({
        isBusy: false,
        paginatedArticles: [] as Array<PaginatedArticle>,
        filters: {
            topicId: '',
            searchTerm: '',
            includeArticleContent: false,
            pageNumber: 1,
            pageSize: 15
        }
    }),
    actions: {
        async loadPaginated(topicId: string) {
            this.filters.topicId = topicId;
            this.isBusy = true;
            const request = axios.get<APIResponse<Array<PaginatedArticle>>>(
                `${endpoints.ARTICLES}/paginated`,
                {
                    params: this.filters
                }
            );
            const response = await useErrorHandling(request);
            this.paginatedArticles = response.data.data;
            this.isBusy = false;
        },
        async loadNextPage() {
            this.isBusy = true;
            this.filters.pageNumber = this.filters.pageNumber + 1;

            const request = axios.get<APIResponse<Array<PaginatedArticle>>>(
                `${endpoints.ARTICLES}/paginated`,
                {
                    params: this.filters
                }
            );
            const response = await useErrorHandling(request);

            if(this.paginatedArticles[0] && response.data.data[0]) {
                this.paginatedArticles[0].articles.items.push(...response.data.data[0].articles.items);
            }
            this.isBusy = false;
        }
    }
})