import { RouteRecordRaw } from "vue-router";
import { canViewRestrictedContent } from "./guards";

export const createEducationRoutes = (routeNamePrefix = '', isRootRoute = true): RouteRecordRaw[] => [
    {
        name: `${routeNamePrefix}education-home`,
        path: `${isRootRoute ? '/' : ''}e`,
        component: () => import('../education-home-page.vue')
    },
    {
        name: `${routeNamePrefix}education-skinTypesAndConditions`,
        path: `${isRootRoute ? '/' : ''}e/skin-types-and-conditions`,
        component: () => import('../skin-type-and-conditions/skin-type-and-conditions-page.vue'),
        beforeEnter: [canViewRestrictedContent],
        meta: { homeRouteName: `${routeNamePrefix}education-home` }
    },
    {
        name: `${routeNamePrefix}education-skinTypes-detail`,
        path: `${isRootRoute ? '/' : ''}e/skin-types-and-conditions/:skinTypeAndConditionId`,
        component: () => import('../skin-type-and-conditions/skin-type-and-conditions-detail-page.vue'),
        redirect: { name: `${routeNamePrefix}education-skinTypes-detail-inner`},
        beforeEnter: [canViewRestrictedContent],
        meta: { homeRouteName: `${routeNamePrefix}education-home` },
        props: true,
        children: [
            {
                name: `${routeNamePrefix}education-skinTypes-detail-inner`,
                path: 'details',
                beforeEnter: [canViewRestrictedContent],
                meta: { homeRouteName: `${routeNamePrefix}education-home` },
                components: {
                    sidePanel: () => import('../skin-type-and-conditions/skin-type-and-conditions-search-form-and-list.vue'),
                    content: () => import('../skin-type-and-conditions/skin-type-and-conditions-information-view.vue')
                },
                props: true
            }
        ]
    },
    {
        name: `${routeNamePrefix}education-products`,
        path: `${isRootRoute ? '/' : ''}e/products`,
        component: () => import('../products/products-page.vue'),
    },
    {
        name: `${routeNamePrefix}education-product-detail`,
        path: `${isRootRoute ? '/' : ''}e/products/:productId`,
        component: () => import('../products/product-detail-page.vue'),
        redirect: { name: `${routeNamePrefix}education-product-detail-inner` },
        props: true,
        children: [
            {
                name: `${routeNamePrefix}education-product-detail-inner`,
                path: 'details',
                components: {
                    sidePanel: () => import('../products/products-page-search-form-and-list.vue'),
                    content: () => import('../products/products-page-product-information-view.vue')
                },
                props: true
            }
        ],
    },
    {
        name: `${routeNamePrefix}education-ingredients`,
        path: `${isRootRoute ? '/' : ''}e/ingredients`,
        beforeEnter: [canViewRestrictedContent],
        meta: { homeRouteName: `${routeNamePrefix}education-home` },
        component: () => import('../ingredients/ingredients-page.vue')
    },
    {
        name: `${routeNamePrefix}education-ingredient-detail`,
        path: `${isRootRoute ? '/' : ''}e/ingredients/:ingredientId`,
        beforeEnter: [canViewRestrictedContent],
        meta: { homeRouteName: `${routeNamePrefix}education-home` },
        component: () => import('../ingredients/ingredient-detail-page.vue'),
        redirect: { name: `${routeNamePrefix}education-ingredient-detail-inner` },
        props: true,
        children: [
            {
                name: `${routeNamePrefix}education-ingredient-detail-inner`,
                path: 'details',
                beforeEnter: [canViewRestrictedContent],
                meta: { homeRouteName: `${routeNamePrefix}education-home` },
                components: {
                    sidePanel: () => import('../ingredients/ingredients-page-search-form-and-list.vue'),
                    content: () => import('../ingredients/ingredients-page-ingredient-information-view.vue')
                },
                props: true
            }
        ],
    },
    {
        name: `${routeNamePrefix}education-articles-topics`,
        path: `${isRootRoute ? '/' : ''}e/articles/topics`,
        alias: `${isRootRoute ? '/' : ''}e/articles`,
        component: () => import('../articles/article-topics-page.vue'),
    },
    {
        name: `${routeNamePrefix}education-articles-topics-detail`,
        path: `${isRootRoute ? '/' : ''}e/articles/topics/:topicId`,
        component: () => import('../articles/article-topics-detail-page.vue'),
    },
    {
        name: `${routeNamePrefix}education-articles-topics-article-detail`,
        path: `${isRootRoute ? '/' : ''}e/articles/topics/:topicId/:articleId`,
        component: () => import('../articles/article-topics-article-detail-page.vue'),
        redirect: { 
            name: `${routeNamePrefix}education-articles-topics-article-detail-inner`,
        },
        props: true,
        children: [
            {
                name: `${routeNamePrefix}education-articles-topics-article-detail-inner`,
                path: 'content',
                components: {
                    sidePanel: () => import('../articles/article-topics-page-search-form-and-list.vue'),
                    content: () => import('../articles/article-topics-article-content-view.vue'),
                },
                props: true
            }
        ]
    }
]

export const routes = createEducationRoutes('')