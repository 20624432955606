import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { OrderRecord, UpdateConsultationOrderRecordRequest } from "../types";
import { useOrdersStore } from "./orders";
import { useNotifications } from "@/modules/core/composables";

const notifications = useNotifications();

export const useOrderStore = defineStore('order', {
    state: () => ({
        orderRecord: null as OrderRecord|null
    }),
    getters: {
        consultationRecord: state => state.orderRecord?.consultationRecord,
        clientRecord: state => state.orderRecord?.consultationRecord.clientRecord
    },
    actions: {
        load (record: OrderRecord) {
            this.orderRecord = record;
        },
        async loadByConsultationId (consultationId: string) {
            const request = axios.get<APIResponse<OrderRecord>>(
                `${endpoints.CONSULTATIONS}/${consultationId}/order-history`
            );

            const response = await useErrorHandling(request);

            this.orderRecord = response.data.data;
        },
        async updateOrderDetails (payload: UpdateConsultationOrderRecordRequest) {
            if (!this.orderRecord) return;

            const consultationId = this.orderRecord.consultationRecord.consultation.id;
            
            const request = axios.put<APIResponse<OrderRecord>>(
                `${endpoints.CONSULTATIONS}/${consultationId}/order-history`,
                payload
            );

            const response = await useErrorHandling(request);

            this.orderRecord = response.data.data;

            notifications.showToast({
                message: 'Order history has been saved successfully',
                type: 'success'
            })

            useOrdersStore().update(response.data.data);
        }
    }
})