import { defineStore } from "pinia";
import { APIResponse, PaginatedData } from "@/modules/core/types";
import { OrganisationTreatmentFormFields } from "../components/settings/inventory/types";
import { useNotifications, useErrorHandling } from "@/modules/core/composables";
import type { ClinicalTreatmentRecord } from "../types";
import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";

const defaultFilters = {
    searchTerm: '',
    pageNumber: 1,
    pageSize: 10
}

const notifications = useNotifications();

export const usePortalSettingsClinicalTreatmentsStore = defineStore('portalSettingsClinicalTreatments', {
    state: () => ({
        isBusy: {
            loading: false,
            filtering: false,
            paginating: false,
            saving: false,
            deleting: false
        },
        filters: defaultFilters,
        paginatedResults: null as PaginatedData<ClinicalTreatmentRecord> | null,
    }),
    actions: {
        async load(filters: typeof defaultFilters|undefined = undefined) {
            this.isBusy.loading = true;
            if(filters) {
                this.$patch({
                    filters
                })
            }
            const request = axios.get<APIResponse<PaginatedData<ClinicalTreatmentRecord>>>(`${endpoints.CLINICAL_TREATMENTS}`, {
                params: this.filters
            })
            const response = await useErrorHandling(request);

            this.paginatedResults = response.data.data;

            this.isBusy.loading = false;
        },
        async create(data: OrganisationTreatmentFormFields) {
            this.isBusy.saving = true;

            const request = axios.post<APIResponse<ClinicalTreatmentRecord>>(`${endpoints.CLINICAL_TREATMENTS}`, data);
            const response = await useErrorHandling(request);

            this.isBusy.saving = false;
        },
        async edit(id: string, data: OrganisationTreatmentFormFields) {
            this.isBusy.saving = true;

            const request = axios.put<APIResponse<ClinicalTreatmentRecord>>(`${endpoints.CLINICAL_TREATMENTS}/${id}`, data);
            const response = await useErrorHandling(request);

            this.isBusy.saving = false;
        },
        async delete(id: string) {
            this.isBusy.deleting = true;
            const request = axios.delete<APIResponse<PaginatedData<ClinicalTreatmentRecord>>>(`${endpoints.CLINICAL_TREATMENTS}/${id}`);
            const response = await useErrorHandling(request);

            this.load();

            notifications.showToast({
                message: 'Treatment has been deleted successfully',
                type: 'success'
            })
            this.isBusy.deleting = false;
        }
    }
})