import { useNotifications } from '@/modules/core/composables';
import { usePortalAuthStore, useOrganisationStore } from '@/modules/proportal/store';
import { NavigationGuard } from 'vue-router';


export const canViewRestrictedContent: NavigationGuard = async (to, from, next) => {
    const authStore = usePortalAuthStore();
    const organisationStore = useOrganisationStore();

    const homeRouteName = to.meta.homeRouteName as string;
    if (!organisationStore.isAllowedToViewRestrictedContent) {
        next({
            replace: true,
            name: homeRouteName,
        })

        useNotifications()
            .showToast({
                message: `You don't have access to view this section`,
                type: 'danger'
            })
        return;
    }
    next()
}