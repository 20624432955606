import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { ArticleTopicRecord } from "../types";

export const useArticleTopicsStore = defineStore('articleTopics', {
    state: () => ({
        isBusy: false,
        articleTopics: [] as Array<ArticleTopicRecord>,
        listLastScrollY: 0
    }),
    actions: {
        async load() {
            this.isBusy = true;
            const request = axios.get<APIResponse<Array<ArticleTopicRecord>>>(
                `${endpoints.ARTICLES}/topics`
            );
            const response = await useErrorHandling(request);
            this.articleTopics = response.data.data;
            this.isBusy = false;
        }
    }
})