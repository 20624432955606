export const API_BASE_URL_V1 = '/api/v1';

export default {
    ANALYTICS: `${API_BASE_URL_V1}/analytics`,
    CONSULTANTS: `${API_BASE_URL_V1}/consultants`,
    CLIENTS: `${API_BASE_URL_V1}/clients`,
    INSTRUCTIONS: `${API_BASE_URL_V1}/instructions`,
    PRODUCTS: `${API_BASE_URL_V1}/products`,
    COUNTRIES: `${API_BASE_URL_V1}/countries`,
    SKIN_TONES: `${API_BASE_URL_V1}/skin-tones`,
    CONSULTATIONS: `${API_BASE_URL_V1}/consultations`,
    SKINCARE_ROUTINE_CATEGORIES: `${API_BASE_URL_V1}/skincare-routine-categories`,
    PRODUCT_CATEGORIES: `${API_BASE_URL_V1}/product-categories`,
    INGREDIENTS: `${API_BASE_URL_V1}/ingredients`,
    FINAL_NOTES: `${API_BASE_URL_V1}/final-notes`,
    ACCOUNT_DETAILS: `${API_BASE_URL_V1}/account-details`,
    CONSULTANT_PRODUCTS: `${API_BASE_URL_V1}/consultant-products`,
    CONSULTANT_INGREDIENTS: `${API_BASE_URL_V1}/consultant-ingredients`,
    PORTAL_SETTINGS: `${API_BASE_URL_V1}/portal-settings`,
    PROFILE_PICTURE: `${API_BASE_URL_V1}/profile-picture`,
    ADDITIONAL_QUESTIONS: `${API_BASE_URL_V1}/additional-questions`,
    APPOINTMENTS: `${API_BASE_URL_V1}/appointments`,
    ORDER_HISTORY: `${API_BASE_URL_V1}/order-history`,
    REPORT_SETTINGS: `${API_BASE_URL_V1}/report-settings`,
    BUSINESS_DETAILS: `${API_BASE_URL_V1}/business-details`,
    ARTICLES: `${API_BASE_URL_V1}/articles`,
    SKIN_MEASUREMENTS: `${API_BASE_URL_V1}/skin-measurements`,
    CLINICAL_TREATMENTS: `${API_BASE_URL_V1}/clinical-treatments`,
    ORGANISATIONS: `${API_BASE_URL_V1}/organisations`,
    BRANDS: `${API_BASE_URL_V1}/brands`,
    INGREDIENT_FUNCTIONS: `${API_BASE_URL_V1}/ingredient-functions`,
    INGREDIENT_DETAILS: `${API_BASE_URL_V1}/ingredient-details`,
    HELP_SUPPORT: `${API_BASE_URL_V1}/help-and-support`
};
