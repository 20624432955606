import { createSendOtpRequest } from "@/modules/proportal/api"
import dayjs from "dayjs"
import { MaybeRef, computed, ref, unref } from "vue"

const lastOtpRequest = ref<Date>()

export const useConsultantOtp = (email: MaybeRef<string>, cooloffInMinutes: MaybeRef<number> = 1) => {

    const canSendOtp = computed(() => {
        if (!lastOtpRequest.value) return true;
        const nextPossibleOtpDT = dayjs(lastOtpRequest.value).add(unref(cooloffInMinutes), 'minutes');
        return dayjs().isAfter(nextPossibleOtpDT)
    })

    const sendOtp = async () => {
        if (!canSendOtp.value) return;
        await createSendOtpRequest(unref(email));
        lastOtpRequest.value = new Date();
        startCounting()
    }

    const timeLeftLabel = ref('');

    const updateLabel = () => {
        const now = dayjs();
        const nextPossibleOtpDT = dayjs(lastOtpRequest.value).add(unref(cooloffInMinutes), 'minutes');
        const diffMinutes = Math.abs(now.diff(nextPossibleOtpDT, 'minutes'));
        const diffSeconds = Math.abs(now.diff(nextPossibleOtpDT, 'seconds') % 60);
        
        timeLeftLabel.value = `${diffMinutes.toString().padStart(2, '0')}:${diffSeconds.toString().padStart(2, '0')}`
    }

    let interval: number|null = null;
    const startCounting = () => {
        if (!lastOtpRequest.value) {
            interval = null;
        }
        if (interval) return;

        interval = window.setInterval(() => {
            if (canSendOtp.value) {
                window.clearInterval(interval!);
                interval = null;
            }

            updateLabel()
        }, 1000)
    }

    const reset = () => {
        lastOtpRequest.value = undefined;
    }

    return {
        sendOtp,
        timeLeftLabel,
        canSendOtp,
        reset
    }
}

export const useResetConsultantOtp = () => {
    return () => {
        lastOtpRequest.value = undefined;
    }
}