import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { ArticleTopicRecord } from "../types";

export const useArticleTopicsDetailStore = defineStore('articleTopicsDetail', {
    state: () => ({
        isBusy: false,
        searchTerm: '',
        articleTopicsDetail: null as ArticleTopicRecord|null
    }),
    actions: {
        async load(topicId: string) {
            this.isBusy = true;
            const request = axios.get<APIResponse<ArticleTopicRecord>>(
                `${endpoints.ARTICLES}/topics/${topicId}`,
                {
                    params: {
                        includeArticles: true,
                        searchTerm: this.searchTerm
                    }
                }
            );
            const response = await useErrorHandling(request);
            this.articleTopicsDetail = response.data.data;
            this.isBusy = false;
        }
    }
})