import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";
import { useErrorHandling } from "@/modules/core/composables";
import type { 
    CreateResetPasswordRequest, 
    CompleteResetPasswordResponse,
    CompleteResetPasswordRequest,
    ChangePasswordRequest,
    PortalConsultantMember, 
    SignInResponse,
    AuthRecord
} from "@/modules/proportal/types";
import { useLocalStorage } from "@vueuse/core";
import Cookies from 'js-cookie';
import appConstants from "../app.constants";
import { APIResponse } from "@/modules/core/types";
import type { SubscriberConsultantRegistrationFormFields, EnterpriseConsultantRegistrationFormFields, LoginFormFields } from "../components/auth/types";
import * as Sentry from '@sentry/vue';

const storageKey = 'proportal.auth';
const rememberKey = 'proportal.auth.rememberMe';

const emptyAtsAuthState = () => ({
    consultant: null as PortalConsultantMember|null,
    accessToken: ''
})

export const usePortalAuthStore = defineStore('portalAuth', {
    state: () => {
        const rememberMe = useLocalStorage(
            rememberKey,
            true,
            { listenToStorageChanges: false }
        );
        const auth = useLocalStorage(
            storageKey,
            emptyAtsAuthState(),
            { listenToStorageChanges: false }
        );

        return {
            isBusy: false,
            rememberMe,
            auth,
            lastUrlKey: 'lastVisitedUrl',
            lastUrlValue: '',
            subscriberConsultantRegistrationData: null as SubscriberConsultantRegistrationFormFields|null,
            enterpriseConsultantRegistrationData: null as EnterpriseConsultantRegistrationFormFields|null,
            show2FA: false,
            tempFormValues: null as LoginFormFields|null
        }
    },
    getters: {
        consultant: state => state.auth.consultant,
        selectedOrganisation: state => state.auth.consultant?.selectedOrganisation,
        customerTerm(): string {
            return this.selectedOrganisation?.customerTerminology.name ?? 'client';
        },
        isMember(): boolean {
            return !this.consultant?.isAdmin && !this.consultant?.isOwner;
        },
        isOwner(): boolean {
            return this.consultant?.isOwner || false;
        }
    },
    actions:{
        async signIn(data: LoginFormFields, dontThrowError = false) {
            this.isBusy = true;

            const request = axios.post<APIResponse<SignInResponse>>(
                `${endpoints.CONSULTANTS}/sign-ins`, { ...data }
            )
            const response = await useErrorHandling(request, undefined, dontThrowError);

            if(response.status === 200) {
                this.$patch({
                    auth: response.data.data,
                    rememberMe: true
                })
                this.saveToStorage();
            } else if (response.status === 202 || response.status === 403) {
                console.log('why????')
                this.tempFormValues = data;
                this.show2FA = true;
            }

            this.isBusy = false;
            
        },
        async registerSubscriberConsultant(data: FormData) {
            // if (!this.subscriberConsultantRegistrationData) return;

            const request = axios.post<APIResponse<AuthRecord>>(
                `${endpoints.CONSULTANTS}/subscribers/registrations/email-passwords`,
                data
            )
            const response = await useErrorHandling(request);
            
            if(response.data.isSuccess && response.data.data) {
                this.auth = response.data.data;

                this.saveToStorage();
            }

            // this.$patch({ subscriberConsultantRegistrationData: null })
        },
        async registerEnterpriseConsultant(data: FormData) {
            if (!this.enterpriseConsultantRegistrationData) return;

            const request = axios.post<APIResponse<any>>(
                `${endpoints.CONSULTANTS}/enterprises/registrations/email-passwords`,
                data
            )
            const response = await useErrorHandling(request);

            this.$patch({ enterpriseConsultantRegistrationData: null })
        },
        async createResetPasswordRequest(data: CreateResetPasswordRequest) {
            const request = axios.post<APIResponse<string>>(`${endpoints.CONSULTANTS}/password-reset-requests`, null, { params: {
                email: data.email
            }});
            return await useErrorHandling(request);
        },
        async completePasswordRequest(data: CompleteResetPasswordRequest) {
            const request = axios.post<APIResponse<CompleteResetPasswordResponse>>(`${endpoints.CONSULTANTS}/password-resets`, data);
            return await useErrorHandling(request);
        },
        async changePasswordRequest(data: ChangePasswordRequest) {
            const request = axios.put(`${endpoints.CONSULTANTS}/password-changes`, data);
            return await useErrorHandling(request);
        },
        // async checkCookieWithServer () {
        //     const accessToken = Cookies.get(appConstants.AUTH_TOKEN_COOKIE_NAME);
        //     if (!accessToken) {
        //         this.logout(false, false);
        //         return;
        //     }

        //     const request = axios.get<APIResponse<PortalConsultantMember>>(`${endpoints.CONSULTANTS}/current`);
        //     const response = await useErrorHandling(request);
        //     this.$patch({
        //         auth: {
        //             consultant: response.data.data,
        //             accessToken
        //         }
        //     })

        //     this.saveToStorage()
        // },
        async getCurrentUser() {
            const accessToken = Cookies.get(appConstants.AUTH_TOKEN_COOKIE_NAME);
            if (!accessToken) {
                this.logout();
                return;
            }
            const request = axios.get<APIResponse<PortalConsultantMember>>(`${endpoints.CONSULTANTS}/current`);
            const response = await useErrorHandling(request, undefined, true);

            if(response.data.data && response.data.data.isDisabled) {
                this.logout(true, false, false);
                return;
            }

            this.$patch({
                auth: {
                    consultant: response.data.data,
                    accessToken
                }
            })

            this.saveToStorage();

        },
        logout(shouldReload = false, shouldRedirect = false, shouldClearStore = true){
            if(shouldClearStore) {
                this.$patch({
                    auth: emptyAtsAuthState()
                })
            }
            localStorage.removeItem(storageKey);
            sessionStorage.removeItem(storageKey);
            Cookies.remove(appConstants.AUTH_TOKEN_COOKIE_NAME);
            Sentry.setUser(null);
            
            if (shouldReload) {
                window.location.reload();
            }
            if (shouldRedirect) {
                window.location.href = `${window.location.origin}/login/#`;
            }
        },
        saveToStorage() {
            localStorage.removeItem(storageKey);
            sessionStorage.removeItem(storageKey);
            Cookies.remove(appConstants.AUTH_TOKEN_COOKIE_NAME);

            const authJson = JSON.stringify(this.auth);
            if (this.rememberMe) {
                localStorage.setItem(storageKey, authJson);
            } else {
                sessionStorage.setItem(storageKey, authJson);
            }
            Sentry.setUser({ email: this.auth.consultant?.email, id: this.auth.consultant?.id.toString() });
            Cookies.set(appConstants.AUTH_TOKEN_COOKIE_NAME, this.auth.accessToken);
        },
        appendUrl(urlName: string){
            this.lastUrlValue = urlName
        },
        resetWhenGoBackToSignIn() {
            this.isBusy = false;
            this.show2FA = false;
        }
    }
})

// export async function portalAuthPlugin() {
//     const store = usePortalAuthStore();
    
//     const cookieAccessToken = Cookies.get(appConstants.AUTH_TOKEN_COOKIE_NAME);
//     const storageAccessToken = store.auth.accessToken;

//     // No cookie and no storage access token
//     if (!cookieAccessToken && !storageAccessToken) {
//         Sentry.setUser(null);
//         return;
//     }

//     // Got cookie but no storage access token
//     if (cookieAccessToken && !storageAccessToken) {
//         // Try to restore auth data from cookie
//         await store.checkCookieWithServer()
//     }

//     store.$subscribe(() => {
//         store.saveToStorage();
//     })
// }