import type { Timezone } from "@/modules/core/types";

export const calculateGMT = (offset: number) => {
    // Calculate the GMT offset
    const gmtOffset = -offset;

    // Format the GMT offset as +/-HH:MM
    const sign = gmtOffset >= 0 ? '+' : '-';
    const hours = Math.abs(Math.floor(gmtOffset / 60)).toString().padStart(2, '0');
    const minutes = (Math.abs(gmtOffset) % 60).toString().padStart(2, '0');

    return `GMT${sign}${hours}:${minutes}`;
}

export const formatTimezoneName = (timezone: Timezone) => {
    return `${timezone.displayName} (${calculateGMT(timezone.offset)})`;
}