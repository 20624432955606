import { defineStore } from "pinia";
import type {
    Gender,
    Country,
    Currency,
    CurrenciesListing,
    FaceZone,
    SkinMeasurementSection,
    SkinCondition,
    ConsultationMethod,
    ConsultationStatus,
    SkincareRoutineCategory,
    PortalColorTheme,
    CustomerTerminology,
    AppointmentType,
    AppointmentDuration,
    ReportTextColour,
    ReportColourTheme,
    Instruction,
    PortalSettings,
} from '@/modules/proportal/types'
import { calculateGMT } from '@/modules/core/utilities';

export const useCommonDataStore = defineStore('commonData', {
    state: () => ({
        genders: [] as Array<Gender>,
        countries: [] as Array<Country>,
        currencies: [] as Array<Currency>,
        currenciesListing: null as CurrenciesListing|null,
        faceZones: [] as Array<FaceZone>,
        skinMeasurementSections: [] as Array<SkinMeasurementSection>,
        skinConditions: [] as Array<SkinCondition>,
        consultationMethods: [] as Array<ConsultationMethod>,
        consultationStatuses: [] as Array<ConsultationStatus>,
        skincareRoutineCategories: [] as Array<SkincareRoutineCategory>,
        portalColorThemes: [] as Array<PortalColorTheme>,
        customerTerminologies: [] as Array<CustomerTerminology>,
        appointmentTypes: [] as Array<AppointmentType>,
        appointmentDurations: [] as Array<AppointmentDuration>,
        reportTextColours: [] as Array<ReportTextColour>,
        reportColourThemes: [] as Array<ReportColourTheme>,
        clientInstructions: [] as Array<Instruction>,
        accuracyInstructions: [] as Array<Instruction>,
        customerPortalUrl: '',
        pricingTableIdWithTrial: '',
        publishableKeyWithTrial: '',
        pricingTableIdWithoutTrial: '',
        publishableKeyWithoutTrial: '',
        portalSettings: null as PortalSettings|null
    }),
    getters: {
        skinMeasurementValues: state => state.skinMeasurementSections.flatMap(x => x.skinMeasurementValues),
        isFollowUpAppointmentType: state => (appointmentTypeId: string) => {
            const appointmentTypesWithConsultationFlowAllowed = state.appointmentTypes.filter(x => x.allowInConsultationFlow);
            return appointmentTypesWithConsultationFlowAllowed.some(x => x.key === appointmentTypeId);
        },
        isDurationPickerEnabledAppointmentType: state => (appointmentTypeId: string) => {
            const appointmentTypesWithConsultationFlowAllowed = state.appointmentTypes.filter(x => x.enableDurationPicker);
            return appointmentTypesWithConsultationFlowAllowed.some(x => x.key === appointmentTypeId);
        },
        getSkinmeasurementSectionByKey: state => (key: string) => {
            return state.skinMeasurementSections.find(x => x.key === key);
        },
        getGenderByKey: state => (key: string) => {
            return state.genders.find(x => x.key === key);
        },
        getConsultationStatusByKey: state => (key: string) => {
            return state.consultationStatuses.find(x => x.key === key);
        },
        getAppointmentTypeByKey: state => (key: string) => {
            return state.appointmentTypes.find(x => x.key === key);
        },
        getTimezoneById: state => (key: string) => {
            const timezones = state.countries.flatMap(x => x.timezones);
            return timezones.find(x => x.key === key);
        },
        getTimezonesByCountry: state => (key: string) => {
            const timezones = state.countries.find(x => x.key === key)?.timezones;
            if(timezones) {
                return timezones.map(x => ({
                    ...x,
                    displayName: `${x.displayName} (${calculateGMT(x.offset)})`
                }))
            }
        
            return [];
        }
    }
})