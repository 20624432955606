import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios, { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { OrderRecord } from "../types";

let load$: Promise<AxiosResponse<APIResponse<OrderRecord[]>>>|null = null;

export const useOrdersStore = defineStore('orders', {
    state: () => ({
        orderRecords: [] as Array<OrderRecord>
    }),
    actions: {
        async load () {
            load$ = load$ || axios.get<APIResponse<Array<OrderRecord>>>(
                endpoints.ORDER_HISTORY
            );
            const response = await useErrorHandling(load$);
            this.orderRecords = response.data.data;
            load$ = null;
        },
        update (orderRecord: OrderRecord) {
            const idx = this.orderRecords.findIndex(x => x.consultationRecord.consultation.id === orderRecord.consultationRecord.consultation.id);
            if (idx === -1) return;

            this.orderRecords.splice(idx, 1, orderRecord);
        }
    }
})