import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";
import { defineStore } from "pinia";
import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import { usePortalConsultationStore, usePortalAuthStore, useOrganisationStore } from "@/modules/proportal/store";
import { 
    NewSkincareRoutineRecord,
    ConsultationLearningNotes,
    ConsultationLearningNotesCreateRequest,
    ClinicalTreatmentRecord,
    CurrentSkincareRoutineStepRecords
} from "../types";
import { useCommonDataStore } from "./proportal";
import { NewSkincareRoutineStepRequestModel, } from "../components/consultation/step-new-skincare-routine/types";


export const usePortalConsultationNewSkincareRoutinesStore = defineStore('portalConsultationNewSkincareRoutines', {
    state: () => ({
        isBusy: false,
        isBusyUpdating: false,
        // skincareRoutineCategories: [] as Array<SkincareRoutineCategory>,
        newSkincareRoutines: [] as Array<NewSkincareRoutineRecord>,
        clinicalTreatments: [] as Array<ClinicalTreatmentRecord>,
        learningNotes: {} as ConsultationLearningNotes,
        currentSkincareRoutineStepRecords: null as Array<CurrentSkincareRoutineStepRecords>|null
    }),
    getters: {
        consultationId: () => usePortalConsultationStore().currentConsultationId,
        // routineStepsByCategoryId: state => (categoryId: string) => state.newSkincareRoutines.find(x => x.skincareRoutineCategory.id === categoryId)?.newSkincareRoutineSteps,
        // routineIndexByCategoryId: state => (categoryId: string) => state.newSkincareRoutines.findIndex(x => x.skincareRoutineCategory.id === categoryId),
        skincareRoutineCategories: () => useCommonDataStore().skincareRoutineCategories,
        getClinicalTreatmentNotes: (state) => {
            return state.clinicalTreatments[0]?.description ?? ''
        },
        getNewRoutineCategory: (state) => {
            return (categoryId: string) => {
                return state.newSkincareRoutines.filter(x => x.skincareRoutineCategoryRecord.id === categoryId)
            }
        },
        getNewSingleCategorySteps: (state) => {
            return (categoryId: string) => {
                const selectedCategory = state.newSkincareRoutines.filter(x => x.skincareRoutineCategoryRecord.id === categoryId)[0]

                return selectedCategory.consultationNewSkincareRoutineStepRecords
            }
        },
        getNewStepsRequestModel: (state) => {
            return state.newSkincareRoutines.map((x) => {
                return {
                    skincareRoutineCategoryId: x.skincareRoutineCategoryRecord.id,
                    notes: x.skincareRoutineCategoryRecord.notes,
                    consultationNewSkincareRoutineStepRequestModels: x.consultationNewSkincareRoutineStepRecords.map(y => {
                        return {
                            productCategoryId: y.productCategoryRecord.id,
                            productId: y.productRecord?.id ?? '',
                            productBrandName: y.productRecord?.productBrandName ?? '',
                            productName: y.productRecord?.productName ?? '',
                            notes: y.notes,
                            purchaseUrl: y.purchaseUrl,
                            recommendationNotes: y.recommendationNotes
                        }
                    })
                }
            })
        },
        previousStepProductIds: (state) => state.currentSkincareRoutineStepRecords?.map(x => x.productRecord?.id),
        previousStepProductCategoryRecord: (state) => state.currentSkincareRoutineStepRecords?.find(x => x.productCategoryRecord)?.productCategoryRecord,
        previousStepProductsSameProductCategory: (state) => state.currentSkincareRoutineStepRecords?.map(x => x.productRecord),
    },
    actions: {
        async editNewRoutineStep(categoryId: string, newStepObj: NewSkincareRoutineStepRequestModel, stepId: String){
            const newStepsRequestModel = this.getNewStepsRequestModel
            const consultationId = usePortalConsultationStore().currentConsultationId

            // Find step to replace
            const routineCategory = this.getNewRoutineCategory(categoryId)[0]
            const indexToChange = routineCategory.consultationNewSkincareRoutineStepRecords.findIndex(y => y.id === stepId)
            const currentRoutineCategory = newStepsRequestModel.filter(y => y.skincareRoutineCategoryId === categoryId)

            // Replace step object
            currentRoutineCategory[0].consultationNewSkincareRoutineStepRequestModels[indexToChange] = newStepObj

            this.isBusyUpdating = true;

            try {
                const request = axios.post(`${endpoints.CONSULTATIONS}/${consultationId}/new-skincare-routine`, newStepsRequestModel)
                const response = await useErrorHandling(request)
    
                this.newSkincareRoutines = response.data.data
            } finally {
                this.isBusyUpdating = false;
            }
        },
        async removeRoutineStep(stepId: string, categoryId: string) {
            const consultationId = usePortalConsultationStore().currentConsultationId

            const routineCategory = this.getNewRoutineCategory(categoryId)[0]
            const stepsArr = routineCategory.consultationNewSkincareRoutineStepRecords
            const removeFromArr = routineCategory.consultationNewSkincareRoutineStepRecords.findIndex(y => y.id === stepId)

            if (removeFromArr > -1) {
                stepsArr.splice(removeFromArr, 1)
            }

            const stepsRequestModel = this.getNewStepsRequestModel
            const request = axios.post(`${endpoints.CONSULTATIONS}/${consultationId}/new-skincare-routine`, stepsRequestModel)
            const response = await useErrorHandling(request)

            this.newSkincareRoutines = response.data.data
        },
        async createNewRoutineStep(categoryId: string, newStepObj: NewSkincareRoutineStepRequestModel){
            const newStepsRequestModel = this.getNewStepsRequestModel
            const consultationId = usePortalConsultationStore().currentConsultationId
            const currentRoutineCategory = newStepsRequestModel.filter(y => y.skincareRoutineCategoryId === categoryId)

            currentRoutineCategory[0].consultationNewSkincareRoutineStepRequestModels.push(newStepObj)

            const request = axios.post(`${endpoints.CONSULTATIONS}/${consultationId}/new-skincare-routine`, newStepsRequestModel)
            const response = await useErrorHandling(request)

            this.newSkincareRoutines = response.data.data
        },
        async getNewSkincareRoutines() {
            if(!this.consultationId) return;

            this.isBusy = true;
            const request = axios.get<APIResponse<Array<NewSkincareRoutineRecord>>>(`${endpoints.CONSULTATIONS}/${this.consultationId}/new-skincare-routine`);

            try {
                const response = await useErrorHandling(request);
                this.newSkincareRoutines = response.data.data;
            } finally {
                this.isBusy = false;
            }
        },
        async deleteRoutine(routineIndex: number) {
            this.newSkincareRoutines.splice(routineIndex, 1);
        },
        async createLearningNotes(notes: ConsultationLearningNotesCreateRequest) {
            
        },
        async sortSkincareRoutineSteps(categoryId: string) {
            const newStepsRequestModel = this.getNewStepsRequestModel
            const consultationId = usePortalConsultationStore().currentConsultationId

            const request = axios.post(`${endpoints.CONSULTATIONS}/${consultationId}/new-skincare-routine`, newStepsRequestModel)
            const response = await useErrorHandling(request)
            
            this.newSkincareRoutines = response.data.data
        },
        async getClinicalTreatments() {
            const request = axios.get<APIResponse<Array<ClinicalTreatmentRecord>>>(`${endpoints.CONSULTATIONS}/${this.consultationId}/clinical-treatments`);
            const response = await useErrorHandling(request);
            this.clinicalTreatments = response.data.data;
        },
        async saveClinicalTreatments(clinicalTreatmentNotes: string) {
            this.isBusy = true

            const consultationStore = usePortalConsultationStore()
            const consultationId = consultationStore.currentConsultationId

            const newRequestObject = {
                consultationClinicalTreatmentsRequestModels: [
                    {
                        title: "Clinical Treatment",
                        description: clinicalTreatmentNotes
                    }
                ]
            }

            const request = axios.post(`${endpoints.CONSULTATIONS}/${consultationId}/clinical-treatments`, newRequestObject)
            const response = await useErrorHandling(request)

            this.isBusy = false
        },
        async editCategoryNotes(notes: string, categoryId: string){
            const consultationId = usePortalConsultationStore().currentConsultationId
            const routineCategory = this.getNewRoutineCategory(categoryId)[0]
            
            routineCategory.skincareRoutineCategoryRecord.notes = notes

            const newStepsRequestModel = this.getNewStepsRequestModel
            const request = axios.post(`${endpoints.CONSULTATIONS}/${consultationId}/new-skincare-routine`, newStepsRequestModel)
            const response = await useErrorHandling(request)
            
            this.newSkincareRoutines = response.data.data
        },
        async duplicateMorningRoutine(targetRoutineId: string) {
            const consultationStore = usePortalConsultationStore()
            const consultationId = consultationStore.currentConsultationId
            const morningRoutineId = this.newSkincareRoutines[0].skincareRoutineCategoryRecord.id

            const request = axios.put<APIResponse<Array<NewSkincareRoutineRecord>>>(`${endpoints.CONSULTATIONS}/${consultationId}/new-skincare-routine/clone?sourceId=${morningRoutineId}&targetId=${targetRoutineId}`)
            const response = await useErrorHandling(request);

            this.newSkincareRoutines = response.data.data;
        },
        async getPrevStepProduct(routineCategoryId: string, productCategoryId: string) {
            // const consultationStore = usePortalConsultationStore()
            // const consultationId = consultationStore.currentConsultationId

            // const request = axios.get<APIResponse>(`${endpoints.CONSULTATIONS}/${this.consultationId}/current-skincare-routine/steps?skincareRoutineCategoryId=${routineCategoryId}&productCategoryId=${productCategoryId}`);

            const request = axios.get<APIResponse<Array<CurrentSkincareRoutineStepRecords>>>(`${endpoints.CONSULTATIONS}/${this.consultationId}/current-skincare-routine/steps`, {
                params: {
                    skincareRoutineCategoryId: routineCategoryId,
                    productCategoryId: productCategoryId
                }
            });

            const response = await useErrorHandling(request);
            
            if(response.data.data.length === 0) return [];

            this.currentSkincareRoutineStepRecords = response.data.data;
            return response.data.data;
        },
    }
})