import { RouteRecordRaw } from "vue-router";
import { orderMaybeExists } from "../../consultation/order/guards";
import { clientMustExist } from "./guards";

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/cl',
        name: 'clients',
        component: () => import('@/modules/proportal/components/clients/portal-clients-listing.vue'),
        children: [
            // {
            //     path: 'listing',
            //     name: 'clients-listing',
            //     component: () => import('@/modules/proportal/components/clients/portal-clients-listing.vue')
            // },
            {
                path: ':clientId',
                name: 'client-details',
                component: () => import('@/modules/proportal/components/clients/portal-offcanvas-client-details.vue'),
                props: {
                    eager: true
                },
                beforeEnter: [clientMustExist]
            },
            {
                path: ':clientId/consultations/:consultationId/order',
                name: 'client-consultation-order-details',
                props: {
                    eager: true,
                },
                component: () => import('../../consultation/order/offcanvas-order-history-details.vue'),
                meta: { previousRoute: 'client-details' },
                beforeEnter: [orderMaybeExists]
            }
        ]
    },

]