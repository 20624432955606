import axios from "axios"
import endpoints from "../endpoints"
import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";

export const createSendOtpRequest = async (email: string) => {
    const request = axios.post<APIResponse<string>>(
        `${endpoints.CONSULTANTS}/emails/send-otp`,
        null,
        {
            params: { email }
        }
    );
    const response = await useErrorHandling(request);
}