import { APIResponse, PaginatedData } from "@/modules/core/types";
import { defineStore } from "pinia";
import { CreateCustomIngredientRequest, IngredientRecord } from "../types";
import axios from "axios";
import endpoints from "../endpoints";
import { useErrorHandling } from "@/modules/core/composables";
import { usePortalConsultationStore } from "./consultation";

export const useConsultationCustomIngredientsStore = defineStore('consultation-custom-ingredients', {
    state: () => ({
        ingredients: [] as IngredientRecord[],
    }),
    getters: {
        consultationId: () => usePortalConsultationStore().currentConsultationId
    },
    actions: {
        async load () {
            if (!this.consultationId) return;
            const request = axios.get<APIResponse<Array<IngredientRecord>>>(
                `${endpoints.CONSULTATIONS}/${this.consultationId}/consultation-custom-ingredients`
            );
            const response = await useErrorHandling(request);
            this.ingredients = response.data.data;
        },
        async createIngredients (data: CreateCustomIngredientRequest) {
            if (!this.consultationId) return [];
            if (data.consultationCustomIngredients.length === 0) return [];
            const request = axios.post<APIResponse<Array<IngredientRecord>>>(
                `${endpoints.CONSULTATIONS}/${this.consultationId}/consultation-custom-ingredients`,
                data
            );
            const response = await useErrorHandling(request);
            this.ingredients.push(...response.data.data)

            return response.data.data;
        }
    }
})