import { defineStore } from "pinia";
import { APIResponse, PaginatedData } from "@/modules/core/types";
import { useErrorHandling } from "@/modules/core/composables";
import { ProductRecord, ConsultantProductCreateAndEditRequest, ProductCategory } from "../types";
import axios from "axios";
import endpoints from "@/modules/proportal/endpoints";

export const usePortalSettingsInventoryStore = defineStore('portalSettingsInventory', {
    state: () => ({
        isBusy: false,
        isFilteringBusy: false,
        isPageUpdatingBusy: false,
        isSavingBusy: false,
        filters: {
            searchTerm: '',
            pageNumber: 1,
            productCategoryIds: [] as Array<string>,
            pageSize: 10
        },
        paginatedResults: null as PaginatedData<ProductRecord> | null,
    }),
    actions: {
        async createConsultantProduct(data: ConsultantProductCreateAndEditRequest) {
            const canModifyList = this.filters.productCategoryIds.length === 0 || data.productCategoryIds.some(x => this.filters.productCategoryIds.includes(x));

            const request = axios.post<APIResponse<PaginatedData<ProductRecord>>>(`${endpoints.CONSULTANT_PRODUCTS}`, data)
            const response = await useErrorHandling(request);

            if(canModifyList) {
                this.paginatedResults = response.data.data;
            }
        

            // if (!this.paginatedResults) return;
            // const { currentPageNumber, totalPages, items, pageSize } = this.paginatedResults;

            // if (currentPageNumber === totalPages) {
            //     this.paginatedResults.count++;

            //     if (items.length === pageSize) {
            //         this.filters.pageNumber++;
            //         this.paginatedResults.currentPageNumber++;
            //         this.paginatedResults.totalPages++;
            //         if (canModifyList) {
            //             this.paginatedResults = response.data.data;
            //         }
            //     } else {
            //         if (canModifyList) {
            //             this.paginatedResults = response.data.data;
            //         }
            //     }
            // }
        },
        async updateConsultantProduct(productId: string, data: ConsultantProductCreateAndEditRequest) {
            this.isSavingBusy = true;
            const request = axios.put<APIResponse<ProductRecord>>(`${endpoints.CONSULTANT_PRODUCTS}/${productId}`, data)
            const response = await useErrorHandling(request);
            const productRecordIndex = this.paginatedResults?.items.findIndex(x => x.id === productId);

            this.isSavingBusy = false;

            if(productRecordIndex === -1) return;

            if(this.paginatedResults) {
                this.paginatedResults.items[productRecordIndex as number] = response.data.data;
            }
        },
        async deleteConsultantProduct(productId: string) {
            const request = axios.delete<APIResponse<ProductRecord>>(`${endpoints.CONSULTANT_PRODUCTS}/${productId}`)
            await useErrorHandling(request);

            if (!this.paginatedResults) return;

            if (this.paginatedResults?.items.length === 1) {
                this.filters.pageNumber--;
            }

            await this.getInventory(this.filters.pageNumber, this.filters.searchTerm, this.filters.productCategoryIds);
        },
        async getInventory(pageNumber = 1, searchTerm = '', productCategoryIds: string[] = [], action: string = '') {
            this.isBusy = true;

            this.$patch({
                filters: {
                    pageNumber,
                    searchTerm,
                    productCategoryIds
                }
            })

            const request = axios.get<APIResponse<PaginatedData<ProductRecord>>>(
                `${endpoints.CONSULTANT_PRODUCTS}`,
                { 
                    params: this.filters
                }
            )
            const response = await useErrorHandling(request);
            
            this.paginatedResults = response.data.data;
            this.isBusy = false;
        },
    }
})