import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { SkinMeasurementSection } from "../types";

export const useSkinMeasurementSectionsStore = defineStore('skinMeasurementSections', {
    state: () => ({
        isBusy: false,
        skinMeasurementSections: [] as Array<SkinMeasurementSection>,
        listLastScrollY: 0,
        searchTerm: ''
    }),
    actions: {
        async load() {
            this.isBusy = true;
            const request = axios.get<APIResponse<Array<SkinMeasurementSection>>>(
                `${endpoints.SKIN_MEASUREMENTS}/sections`,
                {
                    params: {
                        searchTerm: this.searchTerm
                    }
                }
            );
            const response = await useErrorHandling(request);
            this.skinMeasurementSections = response.data.data;
            this.isBusy = false;
        },
        async findById(skinMeasurementSectionId: string) {
            console.log('findById')
            if(this.skinMeasurementSections.length === 0) return;
            console.log('findById found')
            return this.skinMeasurementSections.find(x => x.key === skinMeasurementSectionId);
        },
        getSkinMeasurementCode (skinMeasurementValue: string): string {
            if (!skinMeasurementValue) return '';
            
            return skinMeasurementValue.substring(0, 2);
        }
    }
})