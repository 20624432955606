import type { RouteRecordRaw, NavigationGuard } from "vue-router"
import {
    consultationMustExist, 
    skinMeasurementSectionMustExist
} from "./guards";
import { usePortalConsultationSkinMappingStore, useOrganisationStore } from "@/modules/proportal/store";
import { createEducationRoutes } from '../../education/router';
import { orderMaybeExists } from "../order/guards";

// The route matching with just /:consultationId is not very accurate, there are cases where routes wont load
// Hence, all route names that require the `consultationId` should be prefixed with a /c

const clientsQuotaMustBeEnough: NavigationGuard = async(to, from, next) => {
    if(useOrganisationStore().isClientsQuotaExceeded) {
        next({ name: 'consultation-create-from-existing-client' });
    } else {
        next();
    }
}

export const createEducationOffcanvasRoutes = (
    rootRouteName: string
): RouteRecordRaw[] => [
    {
        name: `${rootRouteName}-educationOffcanvas`,
        path: 'education',
        component: () => import('../education/consultation-education-offcanvas.vue'),
        children: createEducationRoutes(rootRouteName + '-', false),
        redirect: { name: `${rootRouteName}-education-home` },
        props: { eager: true },
    }
]

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/c',
        name: 'consultation-listing',
        component: () => import('../step-consultation-listing.vue'),
    },
    {
        path: '/c/customer-details',
        name: "customer-details",
        component: () => import('../step-clients/step-customer-details.vue'),
        redirect: { name: 'consultation-create-from-new-client' },
        // beforeEnter: [clientsQuotaMustBeEnough],
        children: [
            {
                path: 'new',
                name: 'consultation-create-from-new-client',
                component: () => import('../step-clients/create-consultation-from-new-client-page.vue'),
                beforeEnter: [clientsQuotaMustBeEnough]
            },
            {
                path: 'existing',
                name: 'consultation-create-from-existing-client',
                component: () => import('../step-clients/create-consultation-from-existing-client-page.vue')
            }
        ]
    },
    {
        path: '/c/:consultationId/process',
        name: "process",
        component: () => import('../step-process.vue'),
        beforeEnter: [consultationMustExist],
        children: createEducationOffcanvasRoutes('process'),
        meta: {
            educationOffcanvasStickyRouteName: 'process'
        }
    },
    {
        path: '/c/:consultationId/instructions',
        name: "instructions",
        component: () => import('../step-instruction.vue'),
        beforeEnter: [consultationMustExist],
        children: createEducationOffcanvasRoutes('instructions'),
        meta: {
            educationOffcanvasStickyRouteName: 'instructions'
        }
    },
    {
        path: '/c/:consultationId/personal-health',
        name: "personal-health",
        component: () => import('../step-personal-health/step-personal-health.vue'),
        beforeEnter: [consultationMustExist],
        children: createEducationOffcanvasRoutes('personal-health'),
        meta: {
            educationOffcanvasStickyRouteName: 'personal-health'
        }
    },
    {
        path: '/c/:consultationId/photos',
        name: 'photos-step',
        component: () => import('../step-photos/step-photos.vue'),
        beforeEnter: [consultationMustExist],
        redirect: { name: 'consultation-photos-step-current' },
        children: [
            
            {
                name: 'consultation-photos-step-current',
                path: 'current',
                component: () => import('../step-photos/consultation-photos-current-page.vue'),
                children: [
                    ...createEducationOffcanvasRoutes('consultation-photos-step-current'),
                ],
                meta: {
                    educationOffcanvasStickyRouteName: 'consultation-photos-step-current'
                }
            },
            {
                name: 'consultation-photos-step-previous',
                path: 'previous',
                component: () => import('../step-photos/consultation-photos-viewer-page.vue'),
                children: [
                    ...createEducationOffcanvasRoutes('consultation-photos-step-previous'),
                ],
                meta: {
                    educationOffcanvasStickyRouteName: 'consultation-photos-step-previous'
                }
            },
        ],
    },
    {
        path: '/c/:consultationId/skin-mapping',
        name: 'skin-map',
        component: () => import('../step-skin-measurement/step-skin-mapping.vue'),
        beforeEnter: [consultationMustExist],
        children: [
            {
                name: 'skin-map-section-a',
                component: () => import('../step-skin-measurement/step-a.vue'),
                path: 'step-a',
                children: createEducationOffcanvasRoutes('skin-map-section-a'),
                meta: {
                    educationOffcanvasStickyRouteName: 'skin-map-section-a'
                }
            },
            {
                name: 'skin-map-section',
                component: () => import('../step-skin-measurement/skin-measurement-section-mapping-form.vue'),
                path: ':skinMeasurementSectionId',
                beforeEnter: [skinMeasurementSectionMustExist],
                props: route => {
                    const skinMeasurementStore = usePortalConsultationSkinMappingStore();
                    const section = skinMeasurementStore.skinMeasurementSections.find(x => x.key === route.params.skinMeasurementSectionId);
                    return {
                        section
                    }
                },
                children: createEducationOffcanvasRoutes('skin-map-section'),
                meta: {
                    educationOffcanvasStickyRouteName: 'skin-map-section'
                }
            },
        ]
    },
    {
        path: '/c/:consultationId/skin-needs-identification',
        name: 'skin-needs-identification',
        component: () => import('../step-skin-needs-identification/step-skin-needs-identification.vue'),
        beforeEnter: [consultationMustExist],
        children: [
            {
                path: ':skinMeasurementValueId/edit',
                name: 'skin-measurement-value-edit',
                component: () => import('../step-skin-needs-identification/edit-skin-need-form.vue'),
                props: {
                    eager: true
                }
            },
            ...createEducationOffcanvasRoutes('skin-needs-identification')
        ],
        meta: {
            educationOffcanvasStickyRouteName: 'skin-needs-identification'
        }
    },
    {
        path: '/c/:consultationId/ingredients',
        name: 'ingredients',
        beforeEnter: [consultationMustExist],
        component: () => import('../step-suitable-ingredients/step-suitable-ingredients.vue'),
        children: createEducationOffcanvasRoutes('ingredients'),
        meta: {
            educationOffcanvasStickyRouteName: 'ingredients'
        }
    },
    {
        path: '/c/:consultationId/current-routine',
        name: 'current-routine',
        beforeEnter: [consultationMustExist],
        component: () => import('../step-current-skincare-routine/step-current-skincare-routine.vue'),
        children: createEducationOffcanvasRoutes('current-routine'),
        meta: {
            educationOffcanvasStickyRouteName: 'current-routine'
        }
    },
    {
        path: '/c/:consultationId/new-routine',
        name: 'new-routine',
        beforeEnter: [consultationMustExist],
        component: () => import('../step-new-skincare-routine/step-new-skincare-routine.vue'),
        children: createEducationOffcanvasRoutes('new-routine'),
        meta: {
            educationOffcanvasStickyRouteName: 'new-routine'
        }
    },
    {
        path: '/c/:consultationId/final-notes',
        name: 'final-notes',
        beforeEnter: [consultationMustExist],
        component: () => import('../step-final-notes.vue'),
        children: createEducationOffcanvasRoutes('final-notes'),
        meta: {
            educationOffcanvasStickyRouteName: 'final-notes'
        }
    },
    {
        path: '/c/:consultationId/follow-up-booking',
        name: "follow-up-booking",
        beforeEnter: [consultationMustExist],
        component: () => import('../step-follow-up/follow-up-booking-page.vue'),
    },
    {
        path: '/c/:consultationId/consultation-finished',
        name: "consultation-finished",
        beforeEnter: [consultationMustExist],
        component: () => import('../consultation-finished.vue'),
        children: [
            {
                name: 'consultation-finished-invoice',
                path: 'invoice',
                component: () => import('../order/offcanvas-order-history-details.vue'),
                props: {
                    eager: true
                },
                meta: { previousRoute: 'consultation-finished' },
                beforeEnter: [orderMaybeExists]
            }
        ]
    },
    // Redirect back to consultation listing if route is not found
    // Dont use this regex as it's buggy in some pages
    // {
    //     path: '/:pathMatch(.*)*',
    //     redirect: {
    //         name: 'consultation-listing'
    //     }
    // }
]
