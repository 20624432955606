import { 
    useClientsStore,
    usePortalConsultationStore,
    usePortalConsultationSkinMappingStore, 
    usePortalConsultationSkinNeedsStore, 
    useOrganisationStore,
    // usePortalConsultationSuitableIngredientsStore,
    usePortalAuthStore
} from "@/modules/proportal/store";
import { type RouteLocationNormalized, type NavigationGuard } from "vue-router";
import { routes } from "./routes";

export async function clientsMustExist (to: RouteLocationNormalized, from: RouteLocationNormalized) {
    const clientsStore = useClientsStore();
    if(!clientsStore.clientsPaginated) {
        await clientsStore.getClients();
    }

    return !!clientsStore.clientsPaginated;
}

export async function consultationMustExist (to: RouteLocationNormalized, from: RouteLocationNormalized) {
    // console.log('consultationMustExist guard', to.path)
    const authStore = usePortalAuthStore();
    if (!authStore.consultant) return false;

    const params = to.params;
    const consultationId = params.consultationId as string;
    if (!consultationId) return;
    
    const consultationStore = usePortalConsultationStore();
    const currentConsultation = consultationStore.currentConsultation;

    // Only load consultation when it does not exist or it is not the same consultation
    if (!currentConsultation || consultationStore.consultation?.consultation?.id !== consultationId) {
        // console.log('Loading consultation data in guard...')
        await consultationStore.getConsultation(consultationId);
    }
    // Back button
    const excludedRouteNames = ['customer-details'];
    const routesFiltered = routes.filter(x => !excludedRouteNames.includes(x.name?.toString() ?? ''));

    const currentRouteIndex = routesFiltered.findIndex(x => x.name === to.name);
    // If current route is not the first route in the routes list
    if(currentRouteIndex !== -1 && currentRouteIndex !== 0) {
        consultationStore.previousRoute = routesFiltered[currentRouteIndex - 1];
    } else if (to.name === 'consultation-photos-step-current' || to.name === 'consultation-photos-step-previous') {
        consultationStore.previousRoute = routesFiltered.find(x => x.name === 'personal-health')!;
    } else {
        consultationStore.previousRoute = routesFiltered[0];
    }
    return !!consultationStore.currentConsultation;
}

export async function skinMeasurementSectionMustExist (to: RouteLocationNormalized, from: RouteLocationNormalized) {
    const params = to.params;
    const skinMeasurementSectionId = params.skinMeasurementSectionId as string;
    if (!skinMeasurementSectionId) {
        // console.log('skinMeasurementSectionMustExist: route change prevented due to empty sectionId')
        return false;
    }
 
    const consultationSkinMappingStore = usePortalConsultationSkinMappingStore();
    consultationSkinMappingStore.currentSkinMeasurementSectionId = skinMeasurementSectionId;
    return !!consultationSkinMappingStore.currentSkinMeasurementSection;
}

export async function skinNeedsMustExist(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    const consultationId = usePortalConsultationStore().currentConsultationId;
    if(!consultationId) return false;

    const skinNeedsStore = usePortalConsultationSkinNeedsStore();
    const skinNeeds = skinNeedsStore.skinNeeds;

    if(skinNeeds.length === 0) {
        await skinNeedsStore.getSkinNeeds(consultationId);
    }

    return !!skinNeedsStore.skinNeeds;
}

export const clientsQuotaMustBeEnough: NavigationGuard = async(to, from, next) => {
    if(useOrganisationStore().isClientsQuotaExceeded) {
        next({ name: 'consultation-create-from-existing-client' });
    } else {
        next();
    }
}

// export async function clientsQuotaMustBeEnough(to: RouteLocationNormalized, from: RouteLocationNormalized) {
//     console.log(useOrganisationStore().isClientsQuotaExceeded)
//     return 
// }
