import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { ArticleRecord } from "../types";

export const useArticleTopicsArticleDetailStore = defineStore('articleTopicsArticleDetail', {
    state: () => ({
        isBusy: false,
        searchTerm: '',
        articleDetail: null as ArticleRecord|null
    }),
    actions: {
        async loadById(articleId: string) {
            this.isBusy = true;
            const request = axios.get<APIResponse<ArticleRecord>>(
                `${endpoints.ARTICLES}/${articleId}`,
                {
                    params: {
                        includeArticles: true,
                        searchTerm: this.searchTerm
                    }
                }
            );
            const response = await useErrorHandling(request);
            this.articleDetail = response.data.data;
            this.isBusy = false;
        }
    }
})