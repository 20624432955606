import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse, PaginatedData } from "@/modules/core/types";
import axios from "axios";
import { defineStore } from "pinia";
import endpoints from "../endpoints";
import { Consultation, ConsultationRecord, CreateConsultationRequest } from "../types";
import { usePortalAuthStore, useOrganisationStore } from "@/modules/proportal/store";
import { useCommonDataStore } from "./proportal";

const abortControllers = {
    getConsultation: null as AbortController|null
}

export const usePortalConsultationsStore = defineStore('portalConsultations', {
    state: () => ({
        isBusy: false,
        filters: {
            clientName: '',
            consultationStatusId: '',
            fromDateTime: '',
            toDateTime: '',
            pageNumber: 1,
            skinMeasurementValueIds: [] as string[]
        },
        consultations: [] as Array<ConsultationRecord>,
        paginatedResults: null as PaginatedData<ConsultationRecord> | null
    }),
    getters: {
        consultationStatuses: () => [{ id: 'all', key: '', name: 'All' }, ...useCommonDataStore().consultationStatuses],
        getConsultationStatusByKey: () => (key: string) => useCommonDataStore().getConsultationStatusByKey(key),
        consultationMethods: () => useCommonDataStore().consultationMethods,
        hasFilters: state => [
            state.filters.clientName,
            state.filters.consultationStatusId,
            state.filters.fromDateTime,
            state.filters.toDateTime
        ].some(x => !!x),
        customerTerminologyName: () => useOrganisationStore().customerTerminology?.name,
        skinMeasurementValues: () => useCommonDataStore().skinMeasurementValues
    },
    actions: {
        async getConsultations (pageNumber?: number) {
            this.isBusy = true;
            
            abortControllers.getConsultation?.abort()
            abortControllers.getConsultation = new AbortController()

            if (pageNumber) {
                this.filters.pageNumber = pageNumber;
            }

            try {
                const request = axios.get<APIResponse<PaginatedData<ConsultationRecord>>>(
                    `${endpoints.CONSULTATIONS}/paginated`,
                    {
                        params: { ...this.filters, pageSize: 9 },
                        signal: abortControllers.getConsultation.signal
                    }
                )
                const response = await useErrorHandling(request);
                this.paginatedResults = response.data.data;
                this.consultations = response.data.data.items;
                abortControllers.getConsultation = new AbortController()
            } finally {
                this.isBusy = false;
            }
        },
        async onNextPage() {
            if(!this.paginatedResults) return;
            if(this.filters.pageNumber >= this.paginatedResults.totalPages) return;
            this.filters.pageNumber++;
            await this.getConsultations();
        },
        async onPrevPage() {
            if(this.filters.pageNumber <= 1) return;
            this.filters.pageNumber--;
            await this.getConsultations();
        },
        async onPageChange(pageNumber: number) {
            this.filters.pageNumber = pageNumber;
            await this.getConsultations();
        },
        async createConsultation(consultation: CreateConsultationRequest) {
            const request = axios.post<APIResponse<ConsultationRecord>>(endpoints.CONSULTATIONS, consultation)
            const response = await useErrorHandling(request);
            
            this.consultations.push(response.data.data)
            return response.data.data;
        },
        async cloneFromConsultationById (consultationId: string) {
            const request = axios.post<APIResponse<ConsultationRecord>>(
                `${endpoints.CONSULTATIONS}/${consultationId}/clones`
            )
            const response = await useErrorHandling(request);
            
            this.consultations.push(response.data.data)
            return response.data.data;
        },
        updateConsultation (consultation: Consultation) {
            const consultationRecord = this.consultations.find(x => x.consultation.id === consultation.id);
            if (!consultationRecord) return;

            consultationRecord.consultation = consultation;
        },
        updateConsultationRecord (record: ConsultationRecord) {
            const index = this.consultations.findIndex(x => x.consultation.id === record.consultation.id);
            if (index === -1) return;
            this.consultations.splice(index, 1, record);
        },
        async deleteConsultationById (consultationId: string) {
            const request = axios.delete<APIResponse<Consultation>>(`${endpoints.CONSULTATIONS}/${consultationId}`);
            await useErrorHandling(request);
        }
    }
})