import { useErrorHandling } from "@/modules/core/composables";
import { APIResponse } from '@/modules/core/types';
import endpoints from "@/modules/proportal/endpoints";
import axios from "axios";
import { defineStore } from 'pinia';
import { Consultation, FaceDirection } from '../types';
import { usePortalConsultationStore } from './consultation';

export const usePortalConsultationPhotosStore = defineStore('portalConsultationPhotos', {
    getters: {
        currentConsultation: () => {
            return usePortalConsultationStore().currentConsultation
        },
        imageUrls(): Record<Lowercase<FaceDirection>, string> {
            const consultationId = this.currentConsultation?.consultation.id;
            return {
                front: `${endpoints.CONSULTATIONS}/${consultationId}/media/facial-direction/front`,
                left: `${endpoints.CONSULTATIONS}/${consultationId}/media/facial-direction/left`,
                right: `${endpoints.CONSULTATIONS}/${consultationId}/media/facial-direction/right`
            }
        }
    },
    actions:{
        async uploadImage(file: File, direction: FaceDirection, lastVisitedUrl: string) {
            if (!this.currentConsultation) return;

            const consultationId = this.currentConsultation.consultation.id;
            
            const formData = new FormData();
            formData.append('File', file)
            formData.append('LastVisitedUrl', lastVisitedUrl)

            const request = axios.post<APIResponse<Consultation>>(
                `${endpoints.CONSULTATIONS}/${consultationId}/media/facial-directions/${direction.toLowerCase()}`,
                formData
            )
            const response = await useErrorHandling(request);

            const consultationStore = usePortalConsultationStore();
            const newConsultationRecord = {
                ...this.currentConsultation,
                consultation: response.data.data
            };

            if (direction === 'Front') newConsultationRecord.hasFrontMedia = true;
            if (direction === 'Left') newConsultationRecord.hasLeftMedia = true;
            if (direction === 'Right') newConsultationRecord.hasRightMedia = true;

            consultationStore.updateConsultationRecord(newConsultationRecord);
        },
        async deleteImage(direction: string) {
            if (!this.currentConsultation) return;

            const consultationId = this.currentConsultation.consultation.id;
            const request = axios.delete<APIResponse<Consultation>>(
                `${endpoints.CONSULTATIONS}/${consultationId}/media/facial-directions/${direction.toLowerCase()}`
            )
            const response = await useErrorHandling(request);

            const consultationStore = usePortalConsultationStore();
            const newConsultationRecord = {
                ...this.currentConsultation,
                consultation: response.data.data
            };

            if (direction === 'Front') newConsultationRecord.hasFrontMedia = false;
            if (direction === 'Left') newConsultationRecord.hasLeftMedia = false;
            if (direction === 'Right') newConsultationRecord.hasRightMedia = false;

            consultationStore.updateConsultationRecord(newConsultationRecord);
        }
    }
})